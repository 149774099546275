export const langList: any = {
  menuList: {
    智能设备大屏: 'Smart screen for device',
    智能大屏: 'Smart screen',
    设备诊断: 'Device diagnosis',
    设备管理: 'Device management',
    报告管理: 'Report management',
    系统管理: 'System management',
    智能诊断: 'Intelligent diagnosis',
    诊断趋势: 'Diagnostic trends',
    标准监测: 'Standard monitoring',
    图谱分析: 'Spectrum analysis',
    分析工具: 'Analyzing tool',
    设备事件: 'Device event',
    设备档案: 'Device profile',
    数据下载: 'Data download',
    工况数据: 'Operating data',
    诊断报告: 'Diagnose report',
    个人报告: 'Personal report',
    检修报告: 'Maintenance report',
    周报月报: 'Weekly/Monthly report',
    账户管理: 'Account management',
    巡检管理: 'Inspection management',

    报警管理: 'Alarm management',
    状态监测报警: 'Status monitoring alarm',
    智能诊断报警: 'Intelligent diagnosis alarm',
    智能诊断模型配置: 'Smart diagnosis dodel configuration',
    工厂设备管理: 'Factory equipment management',
    测点管理: 'Measuring point management',
    系统配置: 'System configuration',
    系统日志: 'System log',
    日志管理: 'Log management',
    访问日志: 'Access log',
    工况触发采集配置: 'Condition trigger collection configuration',
    工况数据管理: 'Operating condition data management',
    工况采集卡管理: 'Operating condition capture card management',
    有线采集卡管理: 'Wired capture card management',
    诊断管理: 'Diagnostic management',
    模型配置: 'Model configuration',
    诊断工单管理: 'Diagnostic worksheet management',
    稳定状态清空: 'Steady-state clearing',
    数据清洗配置: 'Data cleaning configuration',
  },
  Chinese: {
    '合并报告': '合并报告',
    '巡检分数趋势': '巡检分数趋势',
    '智能诊断报告': '智能诊断报告',
    '基础巡检报告': '基础巡检报告',
    '全部测点': '全部测点',
    '巡检诊断': '巡检诊断',

    '基础指标': '基础指标',


    '最新巡检状态': '最新巡检状态',
    '最新巡检日期': '最新巡检日期',
    '巡检报告数量': '巡检报告数量',
    '巡检状态统计': '巡检状态统计',
    '巡检设备趋势': '巡检设备趋势',

    '本周巡检设备数': '本周巡检设备数',
    '本周巡检设备健康总览': '本周巡检设备健康总览',
    '幅值图': '幅值图',
    '巡检设备统计': '巡检设备统计',
    '设备运行正常': '设备运行正常',
    '全部区域': '全部区域',
    '巡检管理': '巡检管理',
    '巡检统计': '巡检统计',
    '巡检报告': '巡检报告',
    '巡检周报': '巡检周报',
    '巡检状态': '巡检状态',
    '巡检日期': '巡检日期',
    '日期': '日期',
    '巡检人': '巡检人',
    '巡检报告类型': '巡检报告类型',
    '导出': '导出',
    '请输入设备名称/编号/位号': '请输入设备名称/编号/位号',
    '基础': '基础',
    '智能': '智能',







    '列表显示': '列表显示',
    '台账显示': '台账显示',
    '波形': '波形',
    '压力': '压力',
    '工厂图': '工厂图',
    '编辑工厂': '编辑工厂',
    '新建工厂': '新建工厂',
    '更换工厂图': '更换工厂图',
    '左键添加标记': '左键添加标记',
    '左键按住可拖动': '左键按住可拖动',
    '右键添加标记': '右键添加标记',
    '上级工厂': '上级工厂',
    '请填写区域名称': '请填写区域名称',
    '集团不能为自身工厂': '集团不能为自身工厂',
    '请填写工厂名称': '请填写工厂名称',
    '累计运行': '累计运行',
    '台': '台',
    '设备总数': '设备总数',
    '运行数': '运行数',
    '未关注': '未关注',
    '关注': '关注',
    '设备清单': '设备清单',
    '动轴承润滑不良': '动轴承润滑不良',
    '设备生命周期管理': '设备生命周期管理',
    'AI设备卫士': 'AI设备卫士',
    '智能大屏': '智能大屏',
    '设备诊断4.0': '设备诊断4.0',
    '设备管理4.0': '设备管理4.0',
    '报警管理4.0': '报警管理4.0',
    '报告管理4.0': '报告管理4.0',
    '系统配置': '系统配置',
    '诊断配置': '诊断配置',
    '知识库': '知识库',
    '集团大屏': '集团大屏',
    '厂级大屏': '厂级大屏',
    '设备大屏': '设备大屏',
    '设备健康总览': '设备健康总览',
    '诊断分析': '诊断分析',
    '设备列表': '设备列表',
    '报警列表': '报警列表',
    '推送规则': '推送规则',
    '专家看护配置': '专家看护配置',
    '报告管理': '报告管理',
    '角色管理': '角色管理',
    '账号管理': '账号管理',
    '菜单管理': '菜单管理',
    '工厂配置': '工厂配置',
    //首页
    '监测范围': '监测范围',
    '监控设备数': '监控设备数',
    '监控测点数': '监控测点数',
    '运行设备数(运行率)': '运行设备数(运行率)',
    '全厂运行情况': '全厂运行情况',
    '系统持续服务运行 ': '系统持续服务运行 ',
    '全厂运行设备健康分数': '全厂运行设备健康分数 ',
    '设备健康状态统计': '设备健康状态统计',
    '健康': '健康',
    '可用': '可用',
    '警戒': '警戒',
    '故障': '故障',
    '设备运行状态统计': '设备运行状态统计',
    '运行': '运行',
    '停机': '停机',
    '待机': '待机',
    '设备报警数量统计': '设备报警数量统计',
    '可用报警': '可用报警',
    '警戒报警': '警戒报警',
    '故障报警': '故障报警',
    '未处理': '未处理',
    '已关闭': '已关闭',
    '已检修': '已检修',
    '本年': '本年',
    '本月': '本月',
    '本季度': '本季度',
    '本周': '本周',
    '各厂报警数量排行': '各厂报警数量排行',
    '显示该系统为工厂上线运行的时间': '显示该系统为工厂上线运行的时间',
    '监控设备数：用户所选组织下的监测设备数统计': '监控设备数：用户所选组织下的监测设备数统计',
    '监控测点数：用户所选组织下的监测测点数统计': '监控测点数：用户所选组织下的监测测点数统计',
    '运行设备数：用户所选组织下的监测设备中运行设备数统计': '运行设备数：用户所选组织下的监测设备中运行设备数统计',
    '运行率：用户所选组织下的监测设备中运行设备数/监测设备总数': '运行率：用户所选组织下的监测设备中运行设备数/监测设备总数',
    '设备报警趋势': '设备报警趋势',
    //工厂
    '设备报警记录': '设备报警记录',
    '报警时间': '报警时间',
    '区域清单': '区域清单',
    '显示单位': '显示单位',
    '监测设备': '监测设备',
    '监测测点': '监测测点',
    '运行设备': '运行设备',
    '报警设备': '报警设备',
    '未处理报警': '未处理报警',
    '运行率': '运行率',
    //设备
    '当前运行状态': '当前运行状态',
    '设备厂家': '设备厂家',
    '设备状态占比': '设备状态占比',
    '运行占比': '运行占比',
    '运行时间': '运行时间',
    '健康占比': '健康占比',
    '健康时间': '健康时间',
    '可用占比': '可用占比',
    '可用时间': '可用时间',
    '警戒占比': '警戒占比',
    '警戒时间': '警戒时间',
    '故障占比': '故障占比',
    '故障时间': '故障时间',
    '累计运行时间': '累计运行时间',
    '分数趋势': '分数趋势',
    '报警事件': '报警事件',
    '检修记录': '检修记录',
    '诊断报醒': '诊断报醒',
    //报警列表
    '已处理': '已处理',
    '请选择设备区域': '请选择设备区域',
    '请选择报警类型': '请选择报警类型',
    '请选择严重程度': '请选择严重程度',
    '关闭报警': '关闭报警',
    '下载报警': '下载报警',
    '请选择报警': '请选择报警',
    '请选择未处理的报警': '请选择未处理的报警',
    '请填写关闭原因': '请填写关闭原因',
    '关闭成功': '关闭成功',
    '请耐心等待，完成加载后，点击下载即可下载文件，请勿反复点击下载或关闭本网站': '请耐心等待，完成加载后，点击下载即可下载文件，请勿反复点击下载或关闭本网站',
    //诊断
    '请输入设备名称搜索': '请输入设备名称搜索',
    '请输入测点名称搜索': '请输入测点名称搜索',
    '设备健康状态': '设备健康状态',
    '综合状态': '综合状态',
    '设备基本信息': '设备基本信息',
    '设备位置': '设备位置',
    '收起': '收起',
    '展开': '展开',
    '近两月': '近两月',
    '设备健康事件时间轴': '设备健康事件时间轴',
    '健康指标诊断分数趋势图': '健康指标诊断分数趋势图',
    '振动指标诊断分数趋势图': '振动指标诊断分数趋势图',
    '显示': '显示',
    '隐藏': '隐藏',
    '开关机线': '开关机线',
    '运行时间趋势': '运行时间趋势',
    '指标监测趋势': '指标监测趋势',
    '指标值': '指标值',
    '检修事件': '检修事件',
    '添加检修记录': '添加检修记录',
    '批量关闭报警': '批量关闭报警',
    '排序': '排序',
    '事件类型': '事件类型',
    '诊断报警': '诊断报警',

    //设备列表
    '请选择健康状态': '请选择健康状态',
    '运行状态': '运行状态',
    '请选择运行状态': '请选择运行状态',
    '请输入设备名称/编号': '请输入设备名称/编号',
    '新建设备': '新建设备',
    '测点列表': '测点列表',
    '设备序号': '设备序号',
    '是否实验台': '是否实验台',
    '请选择是否为试验台': '请选择是否为试验台',
    '所属工厂': '所属工厂',
    '报警记录': '报警记录',
    '健康总览': '健康总览',
    '测点位置': '测点位置',
    '请输入测点位置': '请输入测点位置',
    '新建测点': '新建测点',
    '编辑设备': '编辑设备',
    '设备照片': '设备照片',
    '故障信息': '故障信息',
    '故障详情': '故障详情',
    '检修照片': '检修照片',
    '诊断设备': '诊断设备',
    '建议及措施': '建议及措施',

    '编辑测点': '编辑测点',
    '请输入传感器SN': '请输入传感器SN',
    '请输入安装位置': '请输入安装位置',
    '转频提取': '转频提取',
    '请选择转频提取测点': '请选择转频提取测点',
    '请选择设备类型': '请选择设备类型',
    '请选择功率': '请选择功率',
    '请输入编号': '请输入编号',
    '请输入安装坐标': '请输入安装坐标',
    '请输入轴名称': '请输入轴名称',
    '测点照片': '测点照片',
    '请输入备注': '请输入备注',
    '编号': '编号',
    '水平径向': '水平径向',
    '竖直径向': '竖直径向',
    '传感器编号': '传感器编号',
    '轴名称': '轴名称',
    '测点ID': '测点ID',
    '是否试验台': '是否试验台',

    '总貌图': '总貌图',
    '左键按住可拖动，右键添加标记': '左键按住可拖动，右键添加标记',
    '关闭原因': '关闭原因',
    '旋转': '旋转',
    '更换3D图': '更换3D图',
    '选择设备区域': '选择设备区域',
    '选择严重程度': '选择严重程度',
    '请输入关键词搜索': '请输入关键词搜索',

    //报告
    '检修详情': '检修详情',
    '开始时间': '开始时间',
    '结束时间': '结束时间',
    '检修人员': '检修人员',
    '现场设备状态': '现场设备状态',
    '故障描述': '故障描述',
    '健康设备': '健康设备',
    '可用设备': '可用设备',
    '警戒设备': '警戒设备',
    '故障设备': '故障设备',
    '分析周期': '分析周期',

    '故障模式统计(本年)': '故障模式统计(本年)',
    '本周检修/检查': '本周检修/检查',
    '本月检修/检查': '本月检修/检查',
    '周报转发：复制链接转发，用户可在电脑端或手机端查看': '周报转发：复制链接转发，用户可在电脑端或手机端查看',
    '手机端链接': '手机端链接',

    //角色
    '参数配置': '参数配置',
    '操作日志': '操作日志',
    '登录日志': '登录日志',
    '数据变更日志': '数据变更日志',
    '数采器列表': '数采器列表',
    '传感器监测': '传感器监测',
    '服务监测': '服务监测',
    '系统监测报警': '系统监测报警',
    '工况对接配置': '工况对接配置',
    '周报配置': '周报配置',

    '智能诊断报警配置': '智能诊断报警配置',
    '标准监测报警配置': '标准监测报警配置',
    '非标设备管理': '非标设备管理',
    '设备首页': '设备首页',

    '三级菜单测试第二级': '三级菜单测试第二级',
    '三级菜单测试第三级': '三级菜单测试第三级',
    '设备事件报警': '设备事件报警',


    '测试三级2': '测试三级2',
    '故障标准库': '故障标准库',
    '3D模型库': '3D模型库',
    '开关机配置': '开关机配置',
    '采集器列表': '采集器列表',


    '请输入角色名称': '请输入角色名称',
    '请选择菜单': '请选择菜单',
    '请选择工厂权限': '请选择工厂权限',
    '工厂权限': '工厂权限',
    '确定删除此条数据么': '确定删除此条数据么',


    '昵称/用户名/手机号': '昵称/用户名/手机号',
    '请选择所属工厂': '请选择所属工厂',
    '配置': '配置',
    '配置权限': '配置权限',
    '输入用户名': '输入用户名',
    '输入昵称': '输入昵称',
    '不修改密码无需输入': '不修改密码无需输入',

    '菜单名称': '菜单名称',
    '菜单路由': '菜单路由',
    '菜单Code': '菜单Code',
    '上级菜单': '上级菜单',
    '菜单类型': '菜单类型',
    '按钮': '按钮',
    '链接': '链接',
    '路由地址': '路由地址',
    '请输入路由地址': '请输入路由地址',
    '菜单排序': '菜单排序',
    '菜单状态': '菜单状态',
    '一级菜单': '一级菜单',
    '定位': '定位',
    '集团': '集团',
    '请选择集团': '请选择集团',

    '故障释义': '故障释义',
    '一级分类': '一级分类',
    '请输入一级分类': '请输入一级分类',
    '二级分类': '二级分类',
    '请输入二级分类': '请输入二级分类',
    '三级分类': '三级分类',
    '请输入三级分类': '请输入三级分类',
    '缩写': '缩写',
    '请输入缩写': '请输入缩写',
    '模型图': '模型图',

    //诊断配置
    '输入区域或设备名称筛选': '输入区域或设备名称筛选',
    '未选择设备或没有配置测点': '未选择设备或没有配置测点',
    '粘贴': '粘贴',

    '确定删除所选数据么': '确定删除所选数据么',
    '请选择复制的内容': '请选择复制的内容',
    '不允许粘贴': '不允许粘贴',
    '没有复制的内容': '没有复制的内容',
    '请选择内容': '请选择内容',
    '振动参数': '振动参数',


    '请选择项目': '请选择项目',
    '诊断工单(待完成)': '诊断工单(待完成)',
    '诊断工单(已完成)': '诊断工单(已完成)',
    '确认删除': '确认删除',
    '请填写诊断判断备注': '请填写诊断判断备注',
    '保存任务处理': '保存任务处理',

    '请输入切片数': '请输入切片数',
    '请选择工厂后提交': '请选择工厂后提交',
    '周报月报配置': '周报月报配置',
    '报告生成策略': '报告生成策略',
    '是否生成报告': '是否生成报告',
    '每月第': '每月第',
    '每周第': '每周第',
    '天生成月报': '天生成月报',
    '天生成周报': '天生成周报',
    '星期一': '星期一',
    '星期二': '星期二',
    '星期三': '星期三',
    '星期四': '星期四',
    '星期五': '星期五',
    '星期六': '星期六',
    '星期日': '星期日',
    '第': '第',
    '小时生成报告': '小时生成报告',


    '故障信息(请勾选此次维修的故障项)': '故障信息(请勾选此次维修的故障项)',
    '维修详情': '维修详情',
    '故障信息(请勾选此次关闭的故障项)': '故障信息(请勾选此次关闭的故障项)',
    '检修图片': '检修图片',
    '请选择开始时间': '请选择开始时间',
    '请选择结束时间': '请选择结束时间',
    '请填写检修人员': '请填写检修人员',
    '请选择现场设备状态': '请选择现场设备状态',
    '请填写故障原因': '请填写故障原因',
    '请填写检修内容': '请填写检修内容',
    '新增成功': '新增成功',
    '请选择关闭的故障项': '请选择关闭的故障项',
    '低频加速度X(m/s2)': '低频加速度X(m/s2)',
    '低频加速度Y(m/s2)': '低频加速度Y(m/s2)',
    '低频加速度Z(m/s2)': '低频加速度Z(m/s2)',
    '温度(­°C)': '温度(­°C)',
    '速度X(mm/s)': '速度X(mm/s)',
    '速度Y(mm/s)': '速度Y(mm/s)',
    '速度Z(mm/s)': '速度Z(mm/s)',
    '高频加速度(m/s2)': '高频加速度(m/s2)',

    '低频加速度X': '低频加速度X',
    '低频加速度Y': '低频加速度Y',
    '低频加速度Z': '低频加速度Z',
    '速度X': '速度X',
    '速度Z': '速度Z',

    '健康设备数': '健康设备数',
    '可用设备数': '可用设备数',
    '警戒设备数': '警戒设备数',
    '故障设备数': '故障设备数',
    '停机设备数': '停机设备数',
    '待机设备数': '待机设备数',
    '运行设备数': '运行设备数',

    '高频加速度(长)': '高频加速度(长)',
    '速度Y': '速度Y',
    '趋势图': '趋势图',
    '时域图': '时域图',
    '现场情况': '现场情况',
    '有效值': '有效值',
    '倍频': '倍频',
    '边频': '边频',
    '清空标注': '清空标注',
    '值': '值',
    '暂无下载文件': '暂无下载文件',

    '频谱': '频谱',
    '选择范围': '选择范围',
    '包络解调': '包络解调',
    '采样时间': '采样时间',
    '振动指标-均方根': '振动指标-均方根',
    '振动指标-0~0.3KHz均方根': '振动指标-0~0.3KHz均方根',
    '振动指标-0~1KHz均方根': '振动指标-0~1KHz均方根',
    '振动指标-1K~10KHz均方根': '振动指标-1K~10KHz均方根',
    '振动指标-10K~25.6KHz均方根': '振动指标-10K~25.6KHz均方根',
    '振动指标-1K~25.6KHz均方根': '振动指标-1K~25.6KHz均方根',
    '振动指标-0~25.6KHz均方根': '振动指标-0~25.6KHz均方根',
    '振动指标-优化峭度01': '振动指标-优化峭度01',
    '振动指标-平稳冲击烈度': '振动指标-平稳冲击烈度',
    '振动指标-线性峭度02': '振动指标-线性峭度02',
    '振动指标-峭度因子': '振动指标-峭度因子',
    '振动指标-峰峰值': '振动指标-峰峰值',
    '振动指标-优化峰值': '振动指标-优化峰值',
    '振动指标-优化偏度': '振动指标-优化偏度',
    '振动指标-速度均方根': '振动指标-速度均方根',
    '振动指标-频率集中度': '振动指标-频率集中度',
    '振动指标-0.3~1KHz均方根': '振动指标-0.3~1KHz均方根',
    '振动指标-1K~3KHz均方根': '振动指标-1K~3KHz均方根',
    '振动指标-3K~6KHz均方根': '振动指标-3K~6KHz均方根',
    '振动指标-6K~12KHz均方根': '振动指标-6K~12KHz均方根',
    '振动指标-12000~25600Hz均方根': '振动指标-12000~25600Hz均方根',
    '振动指标-6K~10KHz均方根': '振动指标-6K~10KHz均方根',
    '振动指标-优化均方根': '振动指标-优化均方根',
    '振动指标-偏度因子': '振动指标-偏度因子',
    '振动指标-裕度因子': '振动指标-裕度因子',
    '振动指标-峰值指标': '振动指标-峰值指标',
    '振动指标-包络信息熵': '振动指标-包络信息熵',
    '振动指标-包络幅值峭度': '振动指标-包络幅值峭度',
    '振动指标-脉冲因子': '振动指标-脉冲因子',
    '振动指标-波形因子': '振动指标-波形因子',
    '振动指标-均值频率': '振动指标-均值频率',
    '振动指标-主频带位置': '振动指标-主频带位置',
    '振动指标-时域信息熵': '振动指标-时域信息熵',
    '振动指标-频域信息熵': '振动指标-频域信息熵',
    '振动指标-电流指标': '振动指标-电流指标',
    '趋势波形图': '趋势波形图',
    '多时域频谱': '多时域频谱',
    '诊断分数趋势': '诊断分数趋势',
    '倒频谱': '倒频谱',
    '加速度均方根': '加速度均方根',

    '幅值-m/s²': '幅值-m/s²',
    '频率-Hz': '频率-Hz',
    '振动指标-Trms': '振动指标-Trms',
    '振动指标-Trms_300': '振动指标-Trms_300',
    '振动指标-Trms_1000': '振动指标-Trms_1000',
    '振动指标-Trms_1000_10000': '振动指标-Trms_1000_10000',
    '振动指标-Trms_10000_25600': '振动指标-Trms_10000_25600',
    '振动指标-Trms_1000_25600': '振动指标-Trms_1000_25600',
    '振动指标-TLinKurt': '振动指标-TLinKurt',
    '振动指标-TImpactIntensity': '振动指标-TImpactIntensity',
    '振动指标-Tlin_kurt': '振动指标-Tlin_kurt',
    '振动指标-Tkurt': '振动指标-Tkurt',
    '振动指标-Tff': '振动指标-Tff',
    '振动指标-TAccelerationPeak': '振动指标-TAccelerationPeak',
    '振动指标-Tlin_bias': '振动指标-Tlin_bias',
    '振动指标-TVelocityRMS': '振动指标-TVelocityRMS',
    '振动指标-Tcon': '振动指标-Tcon',
    '振动指标-Trms_300_1000': '振动指标-Trms_300_1000',
    '振动指标-Trms_1000_3000': '振动指标-Trms_1000_3000',
    '振动指标-Trms_3000_6000': '振动指标-Trms_3000_6000',
    '振动指标-Trms_6000_12000': '振动指标-Trms_6000_12000',
    '振动指标-Trms_12000_25600': '振动指标-Trms_12000_25600',
    '振动指标-Trms_6000_10000': '振动指标-Trms_6000_10000',
    '振动指标-TAccelerationRMS': '振动指标-TAccelerationRMS',
    '振动指标-Tbias': '振动指标-Tbias',
    '振动指标-Tmargin': '振动指标-Tmargin',
    '振动指标-Tpp': '振动指标-Tpp',
    '振动指标-Tent_e': '振动指标-Tent_e',
    '振动指标-Tenv_kurt': '振动指标-Tenv_kurt',
    '振动指标-Tpulse': '振动指标-Tpulse',
    '振动指标-Twave': '振动指标-Twave',
    '振动指标-TF9': '振动指标-TF9',
    '振动指标-Tmainf': '振动指标-Tmainf',
    '振动指标-Tent_t': '振动指标-Tent_t',
    '振动指标-Tent_f': '振动指标-Tent_f',
    '振动指标-Tcurrent': '振动指标-振动指标-Tcurrent',
    status: ['设备停止', '运行中', '健康', '可用', '警戒', '故障'],
    button: ['近一周', '近一月', '近两月', '自定义', '筛选', '开启', '关闭', '自动刷新', '近一天', '查看全部', '添加', '确认', '取消', '编辑', '搜索', '下载'],
    tip: ['加载中...', '查看', '操作'],
    header: ['提示: 原密码强度过低, 请修改密码！', '原密码：', '新密码：', '*密码不能少于8位', '确认密码：', '修改密码'],
    primitive: [
      '系统持续服务运行',
      '小时',
      '全厂健康分数',
      '分',
      '针对运行中的设备, 状态得分的加权平均数计算为全厂健康分数, 健康设备记100分, 可用设备记80分, 警戒设备记60分, 故障设备记30分',
      '上次更新时间:',
      '更多设备信息',
      '设备报告',
      '个人报告',
      '诊断',
      '检修',
      '周报',
      '月报',
      '更多报告',
    ],
    statusRander: ['总设备数:', '设备诊断雷达图', '健康指标', '振动指标', '有测点数据未传回，因此本条分数数据仅供参考'],
    diagnosis: [
      '设备清单',
      '累计开机时间:',
      '小时',
      '综合健康状态:',
      '当前设备状态:',
      '仅显示有问题详情',
      '设备健康事件时间轴',
      '设备状态变化',
      '现场检查',
      '设备维修',
      '停止运行机器，进行维护',
      '发现明显异常特征，现场检查设备状态，制定维护计划，适时维护设备',
      '发现异常特征，按照巡查计划现场查看设备状态',
      '无需操作',
      '健康指标',
      '振动指标',
      '有测点数据未传回，因此本条分数数据仅供参考',
      '发现异常特征，按照巡查计划现场查看设备状态',
    ],
    histrend: [
      '健康指标诊断分数趋势图',
      '开关机线',
      '显示',
      '隐藏',
      '振动指标诊断分数趋势图',
    ],

    sensorTrend: [
      '传感器指标值',
      '修改门限',
      '指标名称',
      '指标值',
      '门限',
      '传感器信息图表',
      '设备事件时间轴',
      '添加现场检查或维修记录',
      '诊断提醒',
      '现场维修',
      '诊断详情',
      '检修报告详情',
      '检修结果',
      '检修日期',
      '现场设备状态判断',
      '现场反馈详情',
      '时间',
      '健康状态',
      '故障模式',
      '一致性',
      '备注',
      '操作记录',
      '现场照片',
      '未上传',
      '检修信息',
      '检修人',
      '维修记录',
      '开始时间',
      '结束时间',
      '请选择检修开始时间!',
      '请选择检修结束时间!',
      '故障模式描述',
      '用户添加',
      '检修人员',

    ],

    lowwave: [
      '高频加速度',
      '低频速度',
      '时域图',
      '幅值谱图',
      '包络解调',
    ],

    tools: [
      '振动分析工具箱',
      '工具用途 ：',
      '适用/不适用场景 ：',
      '页面布局',
      '刷新数据',
      '指标趋势分析',
      '指标监测',
      '基于高频温振传感器每五分钟监测采集的高频加速度均方根、温度、三向速度均方根的统计参量',
      '对表面小范围剥离或者伤痕、具有冲击振动形式的故障相对而言不敏感；故障模式定位不清晰、不直观',
      '指标值对比分析',
      '可自由选择设备的不同测点、方向、指标，在所选时间段内进行对比分析',
      '通过对比能够简单快速的定位故障部位，明确故障模式；但要提前建立完善的设备诊断模型，少测点、无指标时难以应用',
      '智能诊断分数趋势',
      '反映相同测点的专业和通用指标分数随时间的变化趋势',
      '要提前建立完善的设备诊断模型',
      '频谱分析工具',
      '时域图',
      '显示振动信号的时域波形，幅值随时间变化，对设备故障进行初步判断',
      '时域图是最基本的、最常用的分析方法，也是其它分析方法的基础；但对振动信号中的信息与故障模式不易被判断',
      '幅值谱图',
      '显示各频率的振动分量所具有的振幅',
      '幅值谱图中能发现明显故障特征，能够确定设备具体故障模式；但仅能查看频段能量的分布，并不能体现特定频段能量随时间的变化',
      '包络谱图',
      '解调提取低频冲击信号',
      '对淹没在高频振动中，如轴承故障的特征频率提取',
      '倒频谱图',
      '突出主要频率成分',
      '适合轴承、齿轮等混有多种谐波、多成分变频的信号分析',
      '轴心轨迹图',
      '使用转子水平径向与垂直径向分量绘制轴中心点运动轨迹',
      '适用于需要直观地反应转子在轴承中的旋转和振动等情况；但严重依赖与识图人员的诊断经验，对耦合故障引发的复杂轴心轨迹识别困难',
      '频谱对比工具',
      '瀑布图',
      '反映全部频率分量的振幅与转速变化的情况',
      '适用于启停过程振动信号。图像直观但丧失振动的相位信息，不适用于振动相位分析',
      '趋势对比分析',
      '数据类型：',
      '指标：',
      '时间：',
      '选择时间：',
      '选择测点：',
      '选择类型：',
      '特征频率:',
      '开',
      '关',
      '测点：',
      '类型：',
      '频率范围：',
      '推荐转频：',
      '转频：',
      '（*请点击右上图标查看大图）',
    ],

    machinemsg: [
      '选择事件类型',
      '智能诊断',
      '设备检查',
      '门限报警',
      '选择事件状态',
      '已处理',
      '未处理',
      '选择是否处理',
      '事件时间',
      '事件类型',
      '事件状态',
      '测点',
      '事件内容',
      '联系人',
      '事件报告',
      '诊断报告',
      '检修报告',
    ],

    download: [
      '设备：',
      '时间范围：',
      '数据类型：',
      '稳定状态',
      '运行状态',
      '传感器测点',
      '健康度',
      '采集用户',
      '资产编码',
      '资产型号',
      '资产位号',
      '长度',
      '传感器安装位置',
      '传感器安装方向',
      '采集频率',
      '采集方向',

    ],

    machine: [
      '设备名称',
      '设备型号',
      '设备品牌',
      '安装位置',
      '设备区域',
      '设备位置',
      '添加日期',
      '设备描述',

    ],
    电机驱动端: '电机驱动端',
    一轴输入端: '一轴输入端',
    一轴输出端: '一轴输出端',
    二轴输入端: '二轴输入端',
    二轴输出端: '二轴输出端',
    三轴输出端: '三轴输出端',
    滚筒轴承: '滚筒轴承',

    左侧滚筒轴承: '左侧滚筒轴承',
    左侧电机驱动端: '左侧电机驱动端',
    三轴输入端: '三轴输入端',
    四轴左侧输出端: '四轴左侧输出端',
    四轴右侧输出端: '四轴右侧输出端',
    右侧滚筒轴承: '右侧滚筒轴承',
    一轴左侧输入端: '一轴左侧输入端',
    一轴右侧输入端: '一轴右侧输入端',
    右侧电机驱动端: '右侧电机驱动端',


    四轴输出端: '四轴输出端',


    查看详情: '查看详情',
    返回机房: '返回机房',
    暂无事件: '暂无事件',
    条: '条',
    查看更多: '查看更多',
    点击查看详情: '点击查看详情',
    机房: '机房',
    健康指标: '健康指标',
    振动指标: '振动指标',
    分: '分',
    系统持续服务运行: "系统持续服务运行",
    区域健康评分: '区域健康评分',
    '针对运行中的设备, 状态得分的加权平均数计算为全厂健康分数, 健康设备记100分, 可用设备记80分, 警戒设备记60分, 故障设备记30分': '针对全部设备, 状态得分的加权平均数计算为全厂健康分数, 健康设备记100分, 可用设备记80分, 警戒设备记60分, 故障设备记30分',
    传感器安装位置: '传感器安装位置',
    转速: '转速',
    时间: '时间',
    操作: '操作',
    下载: '下载',
    条数据: '条数据',
    请耐心等待完成加载后点击确认即可下载文件请勿反复点击下载或关闭本网站: '请耐心等待，完成加载后，点击确认即可下载文件，请勿反复点击下载或关闭本网站。',
    共: '共',
    近一天: '近一天',
    近一周: '近一周',
    近一月: '近一月',
    自定义: '自定义',
    筛选: '筛选',
    自动刷新: '自动刷新',
    关闭: '关闭',
    开启: '开启',
    工况数据: '工况数据',
    工况名称: '工况名称',
    工况值: '工况值',
    工况信息图表: '工况信息图表',
    全部设备: '全部设备',
    搜索: '搜索',
    搜索设备信息: '搜索设备信息',
    设备名称: '设备名称',
    设备区域: '设备区域',
    安装位置: '安装位置',
    当前状态: '当前状态',
    健康状态描述: '健康状态描述',
    状态: '状态',
    分数等级: '分数等级',
    建议客户的行为: '建议客户的行为',
    建议使用时间: '建议使用时间',


    可用上: '可用上',
    可用下: '可用下',
    警戒上: '警戒上',
    警戒中: '警戒中',
    警戒下: '警戒下',
    故障上: '故障上',
    故障下: '故障下',
    无: '无',
    无需操作: '无需操作',
    可持续使用: '可持续使用',
    一级可用: '一级可用',
    二级可用: '二级可用',
    一级警戒: '一级警戒',
    二级警戒: '二级警戒',
    发现异常特征关注AI设备卫士中设备分数变化趋势: '发现异常特征，关注AI设备卫士中设备分数变化趋势',
    发现异常特征按照巡查计划现场查看设备状态: '发现异常特征，按照巡查计划现场查看设备状态',
    天07内查看: '0-7天内查看',
    具体时间因设备和故障类型而异: '具体时间因设备和故障类型而异',
    发现明显异常特征现场检查设备状态根据现场检查结果更新维护计划: '发现明显异常特征，现场检查设备状态，根据现场检查结果更新维护计划',
    天03内检查天170内维护: '0-3天内检查，1-70天内维护',
    发现明显异常特征现场检查设备状态制定维护计划适时维护设备: '发现明显异常特征，现场检查设备状态，制定维护计划，适时维护设备',
    天03内检查天130内维护: '0-3天内检查，1-30天内维护',
    停止运行机器进行维护: '停止运行机器，进行维护',
    尽快停机: '尽快停机',
    健康状态: '健康状态',
    运行中: '运行中',
    已停止: '已停止',
    查看: '查看',
    诊断报告: '诊断报告',
    检修报告: '检修报告',
    设备详情: '设备详情',
    设备型号: '设备型号',
    设备品牌: '设备品牌',
    添加日期: '添加日期',
    设备描述: '设备描述',
    个人报告: '个人报告',
    周报月报: '周报月报',
    账户管理: '账户管理',

    报警管理: '报警管理',
    状态监测报警: '状态监测报警',
    智能诊断报警: '智能诊断报警',
    智能诊断模型配置: '智能诊断模型配置',
    工厂设备管理: '工厂设备管理',
    设备管理: '设备管理',
    测点管理: '测点管理',

    系统日志: '系统日志',
    日志管理: '日志管理',
    访问日志: '访问日志',
    工况触发采集配置: '工况触发采集配置',
    工况数据管理: '工况数据管理',
    工况采集卡管理: '工况采集卡管理',
    有线采集卡管理: '有线采集卡管理',
    诊断管理: '诊断管理',
    模型配置: '模型配置',
    诊断工单管理: '诊断工单管理',
    稳定状态清空: '稳定状态清空',
    数据清洗配置: '数据清洗配置',
    搜索设备信息报告内容等: '搜索设备信息、报告内容等',
    选择日期: '选择日期',
    设备: '设备',
    诊断描述: '诊断描述',
    严重程度: '严重程度',
    最近更新: '最近更新',
    轻微: '轻微',
    中度: '中度',
    严重: '严重',
    诊断详情: '诊断详情',
    健康事件: '健康事件',
    可用事件: '可用事件',
    警戒事件: '警戒事件',
    故障事件: '故障事件',
    幅值谱图: '幅值谱图',
    包络谱图: '包络谱图',
    故障原因: '故障原因',
    备注: '备注',
    报告状态: '报告状态',
    数据源: '数据源',
    提交日期: '提交日期',
    已诊断: '已诊断',
    诊断中: '诊断中',
    个人申请: '个人申请',
    巡检记录: '巡检记录',
    异常数据报警: '异常数据报警',
    个人报告信息: '个人报告信息',
    诊断中请耐心等待: '诊断中，请耐心等待',
    当前设备问题严重程度为重度请及时进行检查与维修: '当前设备问题严重程度为重度，请及时进行检查与维修',
    当前设备问题严重程度为中度请密切关注请结合现场情况适时检查: '当前设备问题严重程度为中度，请密切关注，请结合现场情况适时检查。',
    当前设备问题严重程度为轻度请关注: '当前设备问题严重程度为轻度，请关注。',
    诊断结论: '诊断结论',
    报告内容: '报告内容',
    现场照片: '现场照片',
    未上传: '未上传',
    设备信息: '设备信息',
    检修日期: '检修日期',
    检修内容: '检修内容',
    故障类型: '故障类型',
    检修人: '检修人',
    提交时间: '提交时间',
    检修报告详情: '检修报告详情',
    检修结果: '检修结果',
    综合健康状态: '综合健康状态',
    当前设备分数: '当前设备状态',
    现场设备状态判断: '现场设备状态判断',
    现场反馈详情: '现场反馈详情',
    故障模式: '故障模式',
    一致性: '一致性',
    一致: '一致',
    不确定: '不确定',
    不一致: '不一致',
    操作记录: '操作记录',
    检修信息: '检修信息',
    报告标题: '报告标题',
    报告周期: '报告周期',
    报告类型: '报告类型',
    周: '周',
    月报: '月报',
    周报: '周报',
    报告统计时间: '报告统计时间',
    报告日期: '报告日期',
    设备详情清单: '设备详情清单',
    取消: '取消',
    保存: '保存',
    设备状态: '设备状态',
    状态变化: '状态变化',
    诊断结果: '诊断结果',
    未知因素: '未知因素',
    本周检修检查: '本周检修/检查',
    pc端链接: 'pc端链接',
    移动端链接: '移动端链接',
    周报转发分别复制链接转发用户可在电脑端或手机端查看: '周报转发：分别复制链接转发，用户可在电脑端或手机端查看',
    本周设备健康总览: '本周设备健康总览',
    本周设备事件提醒总览: '本周设备事件提醒总览',
    故障模式统计本年: '故障模式统计（本年）',
    有线采集管理: '有线采集管理',
    成功: '成功',
    失败: '失败',
    添加账户: '添加账户',
    昵称: '昵称',
    用户名: '用户名',
    角色: '角色',
    手机号: '手机号',
    是否启用: '是否启用',
    权限标签: '权限标签',
    关键词: '关键词',
    昵称用户名手机号: '昵称/用户名/手机号',
    全部: '全部',
    是: '是',
    否: '否',
    编辑: '编辑',
    停用: '停用',
    启用: '启用',
    重置密码: '重置密码',
    修改密码: '修改密码',
    修改账户: '修改账户',
    请输入用户名: '请输入用户名',
    请输入昵称: '请输入昵称',
    密码: '密码',
    密码至少8位: '密码至少8位',
    随机生成: '随机生成',
    请选择角色: '请选择角色',
    工厂: '工厂',
    请输入手机号: '请输入手机号',
    请选择工厂: '请选择工厂',
    请选择: '请选择',
    确定: '确定',
    重置该用户密码: '重置该用户密码',
    新密码: '新密码',
    旧密码: '旧密码',
    角色名: '角色名',
    查询: '查询',
    菜单: '菜单',
    删除: '删除',
    新增: '新增',
    确认删除该角色: '确认删除该角色!',
    保存成功: '保存成功',
    保存失败: '保存失败',
    编辑成功: '编辑成功',
    操作成功: '操作成功',
    区域: '区域',
    设备id: '设备id',
    设备类型: '设备类型',
    厂家: '厂家',
    型号: '型号',
    机器SN号: '机器SN号',
    序号: '序号',
    是否是试验台: '是否是试验台',
    传感器位置: '传感器位置',
    设备类型缩写: '设备类型缩写',
    生产厂家: '生产厂家',
    设备SN号: '设备SN号',
    试验台: '试验台',
    版本: '版本',
    旗舰版: '旗舰版',
    商业版: '商业版',
    设备图片: '设备图片',
    上传: '上传',
    提交: '提交',
    请输入: '请输入',
    操作失败: '操作失败',
    文件上传失败请联系管理员: '文件上传失败,请联系管理员',
    传感器SN: '传感器SN',
    测点id: '测点id',
    当前设备: '当前设备',
    转频提取测点: '转频提取测点',
    轴参数: '轴参数',
    DT编号: 'DT编号',
    安装坐标: '安装坐标',
    名称: '名称',
    报警类型: '报警类型',
    高频: '高频',
    低频: '低频',
    路径: '路径',
    更新时间: '更新时间',
    当前值: '当前值',
    特性: '特性',
    所属设备: '所属设备',
    功率: '功率',
    三轴参数: '三轴参数',
    数据类型: '数据类型',
    保存路径: '保存路径',
    上次更新: '上次更新',
    添加: '添加',
    选择: '选择',
    搜索测点信息: '搜索测点信息',
    是否推送: '是否推送',
    推送人: '推送人',
    清空: '清空',
    说明: '说明',
    测点: '测点',
    指标名称: '指标名称',
    单位与描述: '单位与描述',
    一级门限可用: '一级门限可用',
    报警间隔天: '报警间隔天',
    二级门限警戒: '二级门限警戒',
    三级门限故障: '三级门限故障',
    不推送: '不推送',
    确认: '确认',
    同步标准: '同步标准',
    基于国际国家或行业内既定标准实现对设备运行状态进行监测同时可纵向参考设备历史运行数据横向比较同类设备运行数据确定各报警等级的阈值: '基于国际、国家或行业内既定标准，实现对设备运行状态进行监测。同时，可纵向参考设备历史运行数据，横向比较同类设备运行数据，确定各报警等级的阈值。',
    但依据标准设定的绝对合理的阈值很难获取设定过高的报警阈值会造成设备故障: '但依据标准设定的绝对合理的阈值很难获取，设定过高的报警阈值会造成设备故障',
    漏报: '漏报',
    的风险而阈值过低又会来带大量的: '的风险，而阈值过低又会来带大量的',
    误报警: '误报警',
    给现场处理造成极大的压力此外仅按照标准规定中的几种指标设定的阈值报警所能发现的故障模式有限设置和修改阈值也面临必需手动调整与工作量大等问题: '，给现场处理造成极大的压力；此外，仅按照标准规定中的几种指标设定的阈值报警所能发现的故障模式有限。设置和修改阈值也面临必需手动调整与工作量大等问题。',
    根据各类国际国家行业标准根据对目前所有设备类型参照相应标准做如下统计: '根据各类国际、国家、行业标准，根据对目前所有设备类型，参照相应标准，做如下统计：',
    采用标准: '采用标准',
    电机类: '电机类',
    泵类: '泵类',
    往复类: '往复类',
    风机类: '风机类',
    齿轮类: '齿轮类',
    螺杆式: '螺杆式',
    汽轮机: '汽轮机',
    通用设备: '通用设备',
    报警策略: '报警策略',
    工况异常处理: '工况异常处理',
    是否专家看护: '是否专家看护',
    分指标报警: '分指标报警',
    按设备整体状态: '按设备整体状态',
    单点即刻报警: '单点即刻报警',
    短期重复出现报警: '短期重复出现报警',
    智能诊断报警策略组合: '智能诊断报警策略组合',
    一旦监测到分数异常即刻报警: '一旦监测到分数异常，即刻报警',
    统计短期内重复出现同类型异常报警去除工况突变数据采集等异常值误报: '统计短期内重复出现同类型异常报警，去除工况突变、数据采集等异常值误报',
    每个指标发生分数恶化都单独发送报警: '每个指标发生分数恶化，都单独发送报警',
    适合处于非健康状态或指标磨合期的设备需要故障模式报警: '适合处于非健康状态，或指标磨合期的设备，需要故障模式报警',
    适合运行较稳定需要故障模式报警: '适合运行较稳定，需要故障模式报警',
    仅当设备整体状态恶化时发送报警: '仅当设备整体状态恶化时，发送报警',
    用于对整体设备状态监测非健康设备报警时效性要求高: '用于对整体设备状态监测，非健康设备报警时效性要求高',
    用于对整体设备状态监测设备运行较稳定趋势性变化突发异常概率低: '用于对整体设备状态监测，设备运行较稳定，趋势性变化突发异常概率低',
    笔内出现: '笔内，出现',
    次: '次',
    开关机影响时间范围: '开关机影响时间范围',
    分钟: '分钟',
    范围: '范围',
    状态回升灵敏度: '状态回升灵敏度',
    距最近开机: '距最近开机',
    笔数据判断状态向前: '笔数据判断状态，向前',
    笔数据判断状态: '笔数据判断状态。',
    请选择工厂后再提交: '请选择工厂后再提交!',
    请选择区域后再提交: '请选择区域后再提交!',
    请选择设备后再提交: '请选择设备后再提交!',
    请选择报警策略后再提交: '请选择报警策略后再提交!',
    请填写工况异常处理后再提交: '请填写工况异常处理后再提交!',
    该区域中存在重复配置的设备: '该区域中存在重复配置的设备',
    首页大屏布局: '首页大屏布局',
    默认版式: '默认版式',
    设备列表版式: '设备列表版式',
    实验采集版式: '实验采集版式',
    操作用户: '操作用户',
    操作路由: '操作路由',
    操作数据: '操作数据',
    操作时间: '操作时间',
    设备编号: '设备编号',
    采集对象: '采集对象',
    工况类型或编号: '工况类型或编号',
    运算符: '运算符',
    门限值: '门限值',
    保持状态延时: '保持状态延时',
    误差率: '误差率',
    波形采集: '波形采集',
    监测指标采集: '监测指标采集',
    秒: '秒',
    确定要删除: '确定要删除？',
    请选择设备后进行操作: '请选择设备后进行操作',
    标签: '标签',
    类型: '类型',
    禁用: '禁用',
    振动加速度: '振动加速度',
    振动速度: '振动速度',
    温度: '温度',
    出口流量: '出口流量',
    频率: '频率',
    实际压力: '实际压力',
    出口压力: '出口压力',
    电流: '电流',
    电压:'电压',
    扭矩: '扭矩',
    未绑定: '未绑定',
    请选择设备: '请选择设备',
    请选择类型: '请选择类型',
    采集卡编码: '采集卡编码',
    采集卡ip: '采集卡ip',
    采集卡类型: '采集卡类型',
    工厂名称: '工厂名称',
    传感器通道: '传感器通道',
    通道: '通道',
    采集卡配置: '采集卡配置',
    基本信息: '基本信息',
    采集配置: '采集配置',
    采集间隔: '采集间隔',
    工况通道配置: '工况通道配置',
    工况通道号: '工况通道号',
    选择测点: '选择测点',
    请填写采集卡编码: '请填写采集卡编码',
    请选择采集卡类型: '请选择采集卡类型',
    请选择区域: '请选择区域',
    请填写安装位置: '请填写安装位置',
    请填写采集卡ip: '请填写采集卡ip',
    请完成工况通道配置的编辑后提交: '请完成工况通道配置的编辑后提交',
    请选择采集卡类型后操作: '请选择采集卡类型后操作！',
    采集卡id: '采集卡id',
    ip地址: 'ip地址',
    边缘电脑绑定: '边缘电脑绑定',
    波形采集配置: '波形采集配置',
    采集方式: '采集方式',
    波形间隔: '波形间隔',
    采样频率: '采样频率',
    采样时长: '采样时长',
    监测指标采集配置: '监测指标采集配置',
    均方根间隔: '均方根间隔',
    触发条件需要同时满足不填写触发条件系统即认为触发条件满足等同于定时采集: '触发条件需要同时满足。不填写触发条件，系统即认为触发条件满足，等同于定时采集',
    通道配置: '通道配置',
    通道号: '通道号',
    关联测点名称: '关联测点名称',
    灵敏度: '灵敏度',
    轴向: '轴向',
    轴向名称: '轴向名称',
    定时采集: '定时采集',
    满足触发条件的定时采集: '满足触发条件的定时采集',
    所选通道数量小于通道配置数量: '所选通道数量小于通道配置数量',
    请选择采集卡类型后再添加: '请选择采集卡类型后再添加',
    通道数量超过所选的: '通道数量超过所选的',
    请填写ip地址: '请填写ip地址',
    请选择波形采集配置的波形间隔: '请选择波形采集配置的波形间隔',
    请选择波形采集配置的采样频率: '请选择波形采集配置的采样频率',
    请选择波形采集配置的采样时长: '请选择波形采集配置的采样时长',
    请选择检测指标采集配置的波形间隔: '请选择检测指标采集配置的波形间隔',
    请选择检测指标采集配置的采样频率: '请选择检测指标采集配置的采样频率',
    请完成通道配置的编辑后提交: '请完成通道配置的编辑后提交',
    通道号过大: '通道号大于',
    请填写灵敏度后提交: '请填写灵敏度后提交',
    切片数: '切片数',
    滤波方式: '滤波方式',
    滤波频段: '滤波频段',
    请选择滤波方式: '请选择滤波方式',
    新增行: '新增行',
    选择设备: '选择设备',
    带通滤波: '带通滤波',
    带阻滤波: '带阻滤波',
    设备选择: '设备选择',
    操作日期范围: '操作日期范围',
    重置: '重置',
    项目: '项目',
    状态清空时间: '状态清空时间',
    负责人: '负责人',
    上一小时状态: '上一小时状态',
    是否改变状态: '是否改变状态',
    设备稳定状态清空: '设备稳定状态清空',
    指标: '指标',
    状态清零记录: '状态清零记录',
    状态回升记录: '状态回升记录',
    该设备在此时间点为关机状态请重新选择: '该设备在此时间点为关机状态，请重新选择',
    选择的时间点暂无稳定状态请重新选择: '选择的时间点暂无稳定状态,请重新选择',
    请选择测点后再选择指标: '请选择测点后再选择指标',
    请选择日期: '请选择日期',
    日期范围: '日期范围',
    设备参数配置: '设备参数配置',
    专业指标: '专业指标',
    通用指标: '通用指标',
    其他: '其他',
    其它: '其它',
    复制: '复制',
    粘贴覆盖: '粘贴覆盖',
    粘贴追加: '粘贴追加',
    恢复为该测点对应的阈值标准值: '恢复为该测点对应的阈值标准值',
    未配置标准则保留配置值: '未配置标准则保留配置值',
    细分故障: '细分故障',
    计算方法: '计算方法',
    全选: '全选',
    复制成功: '复制成功!',
    请先选择需要复制的内容: '请先选择需要复制的内容',
    粘贴成功: '粘贴成功',
    复制内容无法粘贴在该指标下: '复制内容无法粘贴在该指标下!',
    请先复制内容: '请先复制内容!',
    删除成功: '删除成功',
    请先选择需要删除的内容: '请先选择需要删除的内容',
    请保存后再切换: '请保存后再切换!',
    确认执行该操作: '确认执行该操作!',
    返回: '返回',
    部件参数: '部件参数',
    通用参数: '通用参数',
    部件参数新增: '部件参数新增',
    部件类型: '部件类型',
    配置参数: '配置参数',
    数量: '数量',
    参数释义: '参数释义',
    客户: '客户',
    诊断经理: '诊断经理',
    是否采纳: '是否采纳',
    反馈是否有故障: '反馈是否有故障',
    是否曾发送诊断事件: '是否曾发送诊断事件',
    是否更改稳定状态: '是否更改稳定状态',
    诊断处理: '诊断处理',
    发生时间: '发生时间',
    客户经理: '客户经理',
    稳定状态: '稳定状态',
    上一小时稳定状态: '上一小时稳定状态',
    事件状态: '事件状态',
    是否前台显示: '是否前台显示',
    故障模式一致性: '故障模式一致性',
    诊断确认: '诊断确认',
    调整阈值: '调整阈值',
    专家报告: '专家报告',
    状态清零: '状态清零',
    诊断事件: '诊断事件',
    待完成: '待完成',
    已完成: '已完成',
    客户反馈: '客户反馈',
    确认提交: '确认提交',
    导出详情: '导出详情',
    我的未完成项: '我的未完成项',
    取消选择: '取消选择',
    内部事件类型: '内部事件类型',
    警戒及以下: '警戒及以下',
    诊断待处理: '诊断待处理',
    客户支持待处理: '客户支持待处理',
    完成: '完成',
    诊断判断内部备注: '诊断判断内部备注',
    诊断判断备注: '诊断判断备注',
    专家报告id: '专家报告id',
    添加专家报告: '添加专家报告',
    请确认是否清空当前设备稳定状态: '请确认是否清空当前设备稳定状态',
    客户支持: '客户支持',
    事件进展: '事件进展',
    反馈一致性: '反馈一致性',


    自动诊断: '自动诊断',
    人工诊断: '人工诊断',
    客户支持备注: '客户支持备注',
    附件: '附件',
    否无效反馈: '否,无效反馈',
    否模糊: '否,模糊',
    状态反馈一致性: '状态反馈一致性',
    认同: '认同',
    不认同: '不认同',
    修改后认同: '修改后认同',
    部分一致: '部分一致',
    去处理: '去处理',
    有测点数据未传回因此本条分数数据仅供参考: '有测点数据未传回，因此本条分数数据仅供参考',
    传感器测点: '传感器测点',
    健康度: '健康度',
    子指标名称: '子指标名称',
    子指标值: '子指标值',
    添加现场检查或维修记录: '添加现场检查或维修记录',
    查看全部: '查看全部',
    维修记录: '维修记录',
    检查记录: '检查记录',
    检修时间: '检修时间',
    诊断提醒: '诊断提醒',
    现场维修: '现场维修',
    现场检查: '现场检查',
    健康诊断分数趋势图: '健康指标诊断分数趋势图',
    振动指标诊断健康分数: '振动指标诊断分数趋势图',
    指标趋势图: '指标趋势图',
    未开机: '未开机',
    智能诊断: '智能诊断',
    门限报警: '门限报警',
    指标监测: '指标监测',
    智能诊断分数趋势: '智能诊断分数趋势',
    指标值对比分析: '指标值对比分析',
    时域波形图: '时域波形图',
    倒频谱图: '倒频谱图',
    交叉相位图: '交叉相位图',
    轴心轨迹图: '轴心轨迹图',
    瀑布图: '瀑布图',
    高频加速度: '高频加速度',
    幅值: '幅值',
    倒频率: '倒频率',
    加载中: '加载中...',
    "智能诊断-指标值及分数导出": '智能诊断-指标值及分数导出',
    "实时监测-指标值导出": '实时监测-指标值导出',
    "事件消息-事件清单导出": '事件消息-事件清单导出',
    "app采集数据- 采集记录导出": 'app采集数据- 采集记录导出',
    "原始波形文件下载": '原始波形文件下载',
    请选择测点: '请选择测点',
    请选择时间范围: '请选择时间范围',
    请选择数据类型: '请选择数据类型',
    开: '开',
    关: '关',
    全设备测点: '全设备测点',
    设备维修: '设备维修',
    设备检查: '设备检查',
    更换部件: '更换部件',
    维护: '维护',
    返厂: '返厂',
    登录: '登录',
    请输入密码: '请输入密码',
    角色名称: '角色名称',
    "[安全退出]": '[安全退出]',
    工单查看: '工单查看',
    小时: '小时',
    小时s: '小时',
    请输入操作记录: '请输入操作记录',
    用户添加: '用户添加',
    暂无数据: '暂无数据',

    变频设备常用转频: '变频设备常用转频',
    是否需要在该测点数据本身找转频: '是否需要在该测点数据本身找转频',
    额定转频: '额定转频',
    是否需要细化频谱: '是否需要细化频谱',
    额定电源频率: '额定电源频率',
    理论转频所在频谱: '理论转频所在频谱',
    转频换算系数: '转频换算系数',
    转频或部件频率上限: '转频或部件频率上限',
    转频或部件频率下限: '转频或部件频率下限',
    包络谱找转频滤波频段下限: '包络谱找转频滤波频段下限',
    转频数据类型: '转频数据类型',
    转频比值: '转频比值',
    叶轮: '叶轮',
    叶轮级数: '叶轮级数',
    叶片数目: '叶片数目',
    轴承: '轴承',
    保持架特征系数: '保持架特征系数',
    滚动体特征系数: '滚动体特征系数',
    外圈特征系数: '外圈特征系数',
    内圈特征系数: '内圈特征系数',
    轴承型号: '轴承型号',
    齿轮箱: '齿轮箱',
    不超过1个: '不超过1个',
    齿轮箱种是否含有蜗轮蜗杆: '齿轮箱种是否含有蜗轮蜗杆',
    齿数: '齿数',
    蜗杆的头数和蜗轮的齿数: '蜗杆的头数和蜗轮的齿数',
    柱塞泵: '柱塞泵',
    不与其他共同选择: '不与其他共同选择',
    柱塞数目: '柱塞数目',
    柱塞泵滤波参数: '柱塞泵滤波参数',
    柱塞泵转频上限: '柱塞泵转频上限',
    柱塞泵转频下限: '柱塞泵转频下限',
    行星齿轮箱: '行星齿轮箱',
    太阳轮齿数: '太阳轮齿数',
    一倍啮合频率: '一倍啮合频率',
    行星轮个数: '行星轮个数',
    "齿轮箱(新的指标提取方式)": '齿轮箱（新的指标提取方式）',
    齿轮齿数: '行星轮个数',
    螺杆齿数: '行星轮个数',
    刀头磨损: '刀头磨损',
    叶片积灰: '叶片积灰',
    异常冲击: '异常冲击',
    异常特征: '异常特征',
    叶轮异常: '叶轮异常',
    冲击烈度: '冲击烈度',
    机械松动: '机械松动',
    滑动轴承松动: '滑动轴承松动',
    基础松动: '基础松动',
    配合松动: '配合松动',
    机械碰摩: '机械碰摩',
    缺水干磨: '缺水干磨',
    配合不良: '配合不良',
    零件磨损: '零件磨损',
    非平稳故障: '非平稳故障',
    气路共振: '气路共振',
    滚动轴承润滑不良: '滚动轴承润滑不良',
    滑动轴承油膜涡动: '滑动轴承油膜涡动',
    滑动轴承油膜振荡: '滑动轴承油膜振荡',
    滑动轴承烧瓦: '滑动轴承烧瓦',
    滑动轴承磨损: '滑动轴承磨损',
    滚动轴承异常: '滚动轴承异常',
    电磁振动: '电磁振动',
    螺杆啮合不良: '螺杆啮合不良',
    行星齿轮啮合不良: '行星齿轮啮合不良',
    转子不对中: '转子不对中',
    转子不平衡: '转子不平衡',
    连杆异常: '连杆异常',
    部件碰摩: '部件碰摩',
    滑阀碰摩: '滑阀碰摩',
    滑块碰摩: '滑块碰摩',
    滑动轴承碰摩: '滑动轴承碰摩',
    陶瓷片脱落: '陶瓷片脱落',
    齿轮啮合不良: '齿轮啮合不良',


    明显发展: '明显发展',
    发展: '发展',
    轻微发展: '轻微发展',
    异常特征减弱: '异常特征减弱',
    有变化: '有变化',
    无明显变化: '无明显变化',
    恢复健康: '恢复健康',
    短暂开机: '短暂开机',
    传输异常: '传输异常',


    振动强度S1: '振动强度S1',
    振动冲击S2: '振动冲击S2',
    振动平衡S3: '振动平衡S3',
    振动极端S4: '振动极端S4',
    振动集中S5: '振动集中S5',
    振动强度S6: '振动强度S6',
    振动强度S7: '振动强度S7',
    振动强度S8: '振动强度S8',
    振动冲击S9: '振动冲击S9',
    振动冲击S10: '振动冲击S10',
    振动集中S11: '振动集中S11',
    振动冲击S12: '振动冲击S12',
    振动强度S13: '振动强度S13',
    振动冲击S14: '振动冲击S14',
    振动冲击S15: '振动冲击S15',
    振动冲击S16: '振动冲击S16',
    振动冲击S17: '振动冲击S17',
    振动平衡S18: '振动平衡S18',
    振动冲击S19: '振动冲击S19',
    振动强度S20: '振动强度S20',
    振动强度S21: '振动强度S21',
    振动强度S22: '振动强度S22',
    振动强度S23: '振动强度S23',
    振动强度S24: '振动强度S24',
    振动强度S26: '振动强度S26',
    振动强度S27: '振动强度S27',
    电流指标S25: '电流指标S25',
    平稳冲击烈度S28: '平稳冲击烈度S28',
    速度均方根S29: '速度均方根S29',
    优化均方根S30: '优化均方根S30',
    优化峰值S31: '优化峰值S31',
    优化峰值: '优化峰值',
    平稳冲击烈度S32: '平稳冲击烈度S32',
    优化峭度S33: '优化峭度S33',
    振动强度S34: '振动强度S34',
    振动强度S35: '振动强度S35',
    振动强度S36: '振动强度S36',
    总设备数: '总设备数',
    设备数: '设备数',
    速度: '速度',
    高频均方根: '高频均方根',
    智能设备大屏: '智能设备大屏',
    设备诊断: '设备诊断',
    系统管理: '系统管理',
    诊断趋势: '诊断趋势',
    标准监测: '标准监测',
    图谱分析: '图谱分析',
    分析工具: '分析工具',
    设备事件: '设备事件',
    设备档案: '设备档案',
    数据下载: '数据下载',

    泵输入端: '泵输入端',
    滚动轴承润滑特征: '滚动轴承润滑特征',
    信号规律性特征: '信号规律性特征',
    "10～25.6KHz均方根": '10～25.6KHz均方根',
    优化峭度: '优化峭度',
    "1～10KHz均方根": '1～10KHz均方根',
    速度均方根: '速度均方根',
    滚动轴承内圈与外圈: '滚动轴承内圈与外圈',
    "泵输入端存在异常特征，判定为滚动轴承异常，故障部位为轴承内圈和外圈，与2022/1/12事件相比，程度进一步发展；请密切关注设备状态与分数变化情况，并结合现场情况适时安排检查。": '泵输入端存在异常特征，判定为滚动轴承异常，故障部位为轴承内圈和外圈，与2022/1/12事件相比，程度进一步发展；请密切关注设备状态与分数变化情况，并结合现场情况适时安排检查。',
    泵端面: '泵端面',
    非平稳故障01: '非平稳故障01',
    非平稳故障02: '非平稳故障02',
    机械碰摩01: '机械碰摩01',
    机械碰摩02: '机械碰摩02',
    缺水干磨01: '缺水干磨01',
    缺水干磨02: '缺水干磨02',
    配合不良01: '配合不良01',
    配合不良02: '配合不良02',
    零件磨损01: '零件磨损01',
    零件磨损02: '零件磨损02',
    峰峰值: '峰峰值',
    频谱集中度: '频谱集中度',
    "0～0.3KHZ均方根": "0～0.3KHZ均方根",
    全频段均方根: '全频段均方根',
    "0～1KHZ均方根": '0～1KHZ均方根',
    偏度因子: '偏度因子',
    缺水干磨主特征: '缺水干磨主特征',
    振动冲击指标12: '振动冲击指标12',
    振动冲击指标19: '振动冲击指标19',
    振动集中指标11: '振动集中指标11',
    平稳冲击烈度: '平稳冲击烈度',
    碰摩总体特征: '碰摩总体特征',
    碰摩次阶特征: '碰摩次阶特征',
    碰摩谐波特征: '碰摩谐波特征',
    碰摩主特征2: '碰摩主特征2',
    叶片低阶特征: '叶片低阶特征',
    叶片中阶特征: '叶片中阶特征',
    不对中低阶特征: '不对中低阶特征',
    不对中中阶特征: '不对中中阶特征',
    不对中轴向特征: '不对中轴向特征',

    '泵输入端：滚动轴承异常；': '泵输入端：滚动轴承异常；',
    '泵输入端：部件碰摩、转子不对中；': '泵输入端：部件碰摩、转子不对中；',
    '泵输入端：滚动轴承异常、转子不对中与部件碰磨': '泵输入端：滚动轴承异常、转子不对中与部件碰磨',
    滚动轴承内圈和外圈: '滚动轴承内圈和外圈',
    "泵端面：部件碰摩；": '泵端面：部件碰摩；',
    "非平稳故障01，非平稳故障02，振动冲击S19。": '非平稳故障01，非平稳故障02，振动冲击S19。',
    "非平稳故障02。": '非平稳故障02。',

    '2号反渗透1号增压泵': '2号反渗透1号增压泵',
    'A线3号超滤循环泵': 'A线3号超滤循环泵',


    滚动轴承损伤: '滚动轴承损伤',
    "非平稳故障01。": "非平稳故障01。",
    "机械碰磨02。": "机械碰磨02。",
    电源谐波特征: '电源谐波特征',
    不平衡主特征: "不平衡主特征",
    优化峭度指标: '优化峭度指标',
    平稳冲击烈度指标: '	平稳冲击烈度指标',
    振动速度均方根指标: '振动速度均方根指标',
    外圈特征: '外圈特征',
    内圈特征: '内圈特征',
    滚动体特征: '滚动体特征',
    保持架特征: '保持架特征',
    振动加速度峰值指标: '振动加速度峰值指标',
    报告总览: '报告总览',
    报告时间: '报告时间',
    事件是否处理: '事件是否处理',
    变化趋势: '变化趋势',
    'Low-frequency acceleration X (m/s2)': '低频加速度X',
    'Low-frequency acceleration Y (m/s2)': '低频加速度Y',
    'Low-frequency acceleration Z (m/s2)': '低频加速度Z',
    'Temperature (°C)': '温度',
    'Velocity X (mm/s)': '速度X',
    'Velocity Z (mm/s)': '速度Z',
    'Velocity Y (mm/s)': '速度Y',
    'High frequency acceleration (m/s2)': '高频加速度',
    "传感器电量": '传感器电量',
    "设备位号": '设备位号',
    "请输入设备位号": '请输入设备位号',
    "设备功率": '设备功率',
    "请输入设备功率": '请输入设备功率',
    "设备ID": '设备ID',
    "请输入设备ID": '请输入设备ID',
    "设备执行人": '设备执行人',
    "请输入设备执行人": '请输入设备执行人',
    "检测类别": '检测类别',
    "在线监测": '在线监测',
    "巡检": '巡检',
    "报告编号": '报告编号',
    "查看报告": "查看报告",
    "设备绑定": "设备绑定",
    "报警内容": '报警内容',
    '发现异常特征，按照巡查计划现场查看设备状态': '发现异常特征，按照巡查计划现场查看设备状态',
    '发现明显异常特征，现场检查设备状态，根据现场检查结果更新维护计划，适时维护设备': '发现明显异常特征，现场检查设备状态，根据现场检查结果更新维护计划，适时维护设备',
    '设备故障严重，建议停机维修': '设备故障严重，建议停机维修',
    '1K～10KHz均方根': '1K～10KHz均方根',
    '10K～25.6KHz均方根': '10K～25.6KHz均方根',
    '分数': '分数',
    '位移峰峰值': '位移峰峰值',
    '巡检阈值管理': '巡检阈值管理',

    '测点名称': '测点名称',
    'FX轴承因子': 'FX轴承因子',
    'FX冲击因子': 'FX冲击因子',
    '执行人': '执行人',

    '啮合冲击': '啮合冲击',
    '星轮磨损': '星轮磨损',
    '滑动轴承润滑不良': '滑动轴承润滑不良',
    '碰磨': '碰磨',
    '磨盘磨损': '磨盘磨损',
    '螺杆啮合不良01': '螺杆啮合不良01',
    '螺杆啮合不良03': '螺杆啮合不良03',
    '螺杆啮合不良02': '螺杆啮合不良02',
    '连杆异常02': '连杆异常02',
    '连杆异常1': '连杆异常1',
    '螺杆碰摩': '螺杆碰摩',
    '巡检序号': '巡检序号',
    '全部报告': '全部报告',
    '报警报告': '报警报告',
    '健康报告': '健康报告',
    '巡检设备细分':'巡检设备细分',
    '智能诊断巡检配置':'智能诊断巡检配置',
    '请选择设备细分':'请选择设备细分',
    '处理状态': '处理状态',






  },
  English: {
    '处理状态': 'Processing status',
    '巡检设备细分': 'Inspection equipment segmentation',
    '智能诊断巡检配置':'Intelligent Diagnostic Inspection Configuration',
'请选择设备细分': 'Please select equipment segmentation',
    '全部报告': 'All reports',
    '报警报告': 'Alarm report',
    '健康报告': 'Healthy report',
    '巡检序号': 'sort',
    '啮合冲击': 'Meshing Impact',
    '星轮磨损': 'Star Wheel Wear',
    '滑动轴承润滑不良': 'Poor Lubrication of Plain Bearings',
    '碰磨': 'Collision and Abrasion',
    '磨盘磨损': 'Grinding Wheel Wear',
    '螺杆啮合不良01': 'Screw Misalignment 01',
    '螺杆啮合不良03': 'Screw Misalignment 03',
    '螺杆啮合不良02': 'Screw Misalignment 02',
    '连杆异常02': 'Connecting Rod Abnormality 02',
    '连杆异常1': 'Connecting Rod Abnormality 1',
    '螺杆碰摩': 'Screw Abrasion',
    '执行人': 'Executor',
    '位移峰峰值': 'Peak-to-Peak Displacement',
    '测点名称': 'Sensor Name',
    'FX轴承因子': 'FX-Bearing Factor',
    'FX冲击因子': 'FX-Impulsive Factor',

    '基础指标': 'Basic Indicators',
    '合并报告': 'Merged Report',
    '巡检分数趋势': 'Inspection Score Trends',
    '智能诊断报告': 'Intelligent diagnostic report',
    '基础巡检报告': 'Basic Inspection Report',
    '全部测点': 'All Measurement Points',
    '巡检诊断': 'Inspection Diagnosis',
    '巡检状态统计': 'Inspection Status Statistics',
    '巡检设备趋势': 'Inspection Equipment Trends',
    '最新巡检状态': 'Latest Inspection Status',
    '最新巡检日期': 'Latest Inspection Date',
    '巡检报告数量': 'Number of Inspection Reports',
    '巡检设备统计': 'Inspection Equipment Statistics',
    '本周巡检设备数': 'Number of Inspected Devices this Week',
    '本周巡检设备健康总览': 'Overview of Device Healthy Inspections this Week',
    '加速度均方根': 'Root Mean Square Acceleration',
    '幅值图': 'Amplitude Chart',
    '现场情况': 'On-site Situation',
    '巡检管理': 'Inspection management',
    '巡检统计': 'Inspection Statistics',
    '巡检报告': 'Inspection Report',
    '巡检周报': 'Weekly Inspection Report',
    '巡检状态': 'Inspection Status',
    '巡检日期': 'Inspection Date',
    '日期': 'Date',
    '巡检人': 'Inspector',
    '巡检报告类型': 'Inspection Report Type',
    '请输入设备名称/编号/位号': 'Please enter the device name/number/location',
    '基础': 'Basics',
    '智能': 'Smart',

    '动轴承润滑不良': 'Poor Lubrication of Rolling Bearings',
    '列表显示': 'List display',
    '设备运行正常': 'Equipment Operating Normally',
    '全部区域': 'All Areas',
    '台账显示': 'Account display',
    '波形': 'Waveform',
    '压力': 'Pressure',
    '工厂图': 'Factory Map',
    '编辑工厂': 'Edit Factory',
    '新建工厂': 'Create New Factory',
    '更换工厂图': 'Change Factory Map',
    '左键添加标记': 'Left-click to Add Marker',
    '左键按住可拖动': 'Hold Left-click to Drag',
    '右键添加标记': 'Right-click to Add Marker',
    '上级工厂': 'Parent Factory',
    '请填写区域名称': 'Please Enter Area Name',
    '集团不能为自身工厂': 'Group cannot be its own factory',
    '请填写工厂名称': 'Please Enter Factory Name',
    '累计运行': 'Total Runtime',
    '台': 'Units',
    '设备总数': 'Total Equipment',
    '运行数': 'Operating Count',
    '未关注': 'Not Followed',
    '关注': 'Follow',
    '设备清单': 'Equipment List',
    'AI设备卫士': 'AI Machine Guard',
    '设备诊断4.0': 'Device diagnosis 4.0',
    '设备管理4.0': 'Device Management 4.0',
    '报警管理4.0': 'Alarm Management 4.0',
    '报告管理4.0': 'Report Management 4.0',
    '系统配置': 'System Configuration',
    '诊断配置': 'Diagnostic Configuration',
    '知识库': 'Knowledge Base',
    '集团大屏': 'Group Screen',
    '厂级大屏': 'Factory Screen',
    '设备大屏': 'Device Screen',
    '设备健康总览': 'Device Healthy Overview',
    '诊断分析': 'Diagnostic Analysis',
    '设备列表': 'Device List',
    '报警列表': 'Alarm List',
    '推送规则': 'Push Rules',
    '专家看护配置': 'Expert Care Configuration',
    '报告管理': 'Report Management',
    '角色管理': 'Role Management',
    '账号管理': 'Account Management',
    '菜单管理': 'Menu Management',
    '工厂配置': 'Factory Configuration',
    //首页
    '监测范围': 'Monitoring Scope',
    '监控设备数': 'Number of Monitored Devices',
    '监控测点数': 'Number of Monitoring Points',
    '运行设备数(运行率)': 'Number of Running Devices (Running Rate)',
    '全厂运行情况': 'Overall Plant Operation',
    '系统持续服务运行 ': 'Continuous System Operation ',
    '全厂运行设备健康分数': 'Overall Plant Device Healthy Score',
    '分数': 'Score',
    '设备健康状态统计': 'Device Healthy Status Statistics',
    '健康': 'Healthy',
    '可用': 'Alert',
    '警戒': 'Alarm',
    '故障': 'Danger',
    '设备运行状态统计': 'Device Operation Status Statistics',
    '运行': 'Running',
    '停机': 'Stopped',
    '待机': 'Stopped',
    '设备报警数量统计': 'Device Alarm Count Statistics',
    '可用报警': 'Alert Alarms',
    '警戒报警': 'Alarm Alarms',
    '故障报警': 'Danger Alarms',
    '未处理': 'Unprocessed',
    '已关闭': 'Closed',
    '已检修': 'Under Maintenance',
    '本年': 'This Year',
    '本月': 'This Month',
    '本季度': 'This Quarter',
    '本周': 'This Week',
    '各厂报警数量排行': 'Alarm Count Ranking by Factory',
    '显示该系统为工厂上线运行的时间': 'Display the time when the system went online for factory operation',
    '监控设备数：用户所选组织下的监测设备数统计': 'Number of Monitored Devices: Statistics of monitored devices under the selected organization',
    '监控测点数：用户所选组织下的监测测点数统计': 'Number of Monitoring Points: Statistics of monitoring points under the selected organization',
    '运行设备数：用户所选组织下的监测设备中运行设备数统计': 'Number of Running Devices: Statistics of running devices among monitored devices under the selected organization',
    '运行率：用户所选组织下的监测设备中运行设备数/监测设备总数': 'Running Rate: Running devices count among monitored devices under the selected organization divided by the total monitored devices count',
    '设备报警趋势': 'Device Alarm Trend',
    //工厂
    '设备报警记录': 'Device Alarm Records',
    '报警时间': 'Alarm Time',
    '区域清单': 'Area List',
    '显示单位': 'Display Unit',
    '监测设备': 'Monitored Devices',
    '监测测点': 'Monitoring Points',
    '运行设备': 'Running Devices',
    '报警设备': 'Alarm Devices',
    '未处理报警': 'Unresolved Alarms',
    '运行率': 'Operation Rate',
    //设备
    '当前运行状态': 'Current Operating Status',
    '设备厂家': 'Equipment Manufacturer',
    '设备状态占比': 'Device Status Percentage',
    '运行占比': 'Operating Percentage',
    '运行时间': 'Operating Time',
    '健康占比': 'Healthy Percentage',
    '健康时间': 'Healthy Time',
    '可用占比': 'Alert Percentage',
    '可用时间': 'Alert Time',
    '警戒占比': 'Alarm Percentage',
    '警戒时间': 'Alarm Time',
    '故障占比': 'Danger Percentage',
    '故障时间': 'Danger Time',
    '累计运行时间': 'Total Operating Time',
    '分数趋势': 'Score Trend',
    '报警事件': 'Alarm Events',
    '检修记录': 'Maintenance Records',
    '诊断报醒': 'Diagnostic Alarm',
    //报警列表
    '已处理': 'Resolved',
    '请选择设备区域': 'Select Equipment Area',
    '请选择报警类型': 'Select Alarm Type',
    '请选择严重程度': 'Select Severity',
    '下载报警': 'Download Alarm',
    '关闭报警': 'Close Alarm',
    '请选择报警': 'Select Alarm',
    '请选择未处理的报警': 'Select Unresolved Alarm',
    '请填写关闭原因': 'Please Enter Closure Reason',
    '关闭成功': 'Closure Successful',
    '请耐心等待，完成加载后，点击下载即可下载文件，请勿反复点击下载或关闭本网站': 'Please wait patiently. After the loading is complete, click on download to download the file. Please do not repeatedly click on download or close this website',
    // '操作失败': 'Operation Failed',
    '有测点数据未传回，因此本条分数数据仅供参考': 'Some measurement point data has not been returned, so the score data in this entry is for reference only',
    '请输入设备名称搜索': 'Enter Device Name to Search',
    '请输入测点名称搜索': 'Enter Measurement Point Name to Search',
    '设备健康状态': 'Device Healthy Status',
    '综合状态': 'Overall Status',
    '设备基本信息': 'Basic Device Information',
    '设备位置': 'Device Location',
    '收起': 'Collapse',
    '展开': 'Expand',
    '近两月': 'Last Two Months',
    '设备健康事件时间轴': 'Device Healthy Event Timeline',
    '健康指标诊断分数趋势图': 'Healthy Metric Diagnostic Score Trend Chart',
    '振动指标诊断分数趋势图': 'Vibration index diagnostic score trend chart',
    '显示': 'Show',
    '隐藏': 'Hide',
    '开关机线': 'On/Off Line',
    '运行时间趋势': 'Runtime Trend',
    '指标监测趋势': 'Metric Monitoring Trend',
    '指标值': 'Metric Value',

    '检修事件': 'Maintenance Event',
    '添加检修记录': 'Add Maintenance Record',
    '批量关闭报警': 'Bulk Close Alarms',
    '排序': 'Sort',
    '事件类型': 'Event Type',
    '诊断报警': 'Diagnostic Alarm',
    '请选择健康状态': 'Select Healthy Status',
    '运行状态': 'Operating Status',
    '请选择运行状态': 'Select Operating Status',
    '请输入设备名称/编号': 'Enter Device Name/Number',
    '新建设备': 'New Device',
    '测点列表': 'Point List',
    '设备序号': 'Device Number',
    '是否实验台': 'Is Experimental Platform',
    '请选择是否为试验台': 'Please select if it is a test bench',
    '所属工厂': 'Belongs to Factory',
    '报警记录': 'Alarm Records',
    '健康总览': 'Healthy Overview',
    '测点位置': 'Point Location',
    '请输入测点位置': 'Enter Point Location',
    '新建测点': 'Create New Point',
    '编辑设备': 'Edit Device',
    '设备照片': 'Device Photo',
    '故障信息': 'Danger Information',
    '故障详情': 'Danger Details',
    '检修照片': 'Maintenance Photo',
    '诊断设备': 'Diagnose Device',
    '建议及措施': 'Recommendations and Measures',

    '编辑测点': 'Edit Point',
    '请输入传感器SN': 'Please enter sensor SN',
    '请输入安装位置': 'Please enter installation location',
    '转频提取': 'Speed Extraction',
    '请选择转频提取测点': 'Please select speed extraction point',
    '请选择设备类型': 'Please select device type',
    '请选择功率': 'Please select power',
    '请输入编号': 'Please enter number',
    '请输入安装坐标': 'Please enter installation coordinates',
    '请输入轴名称': 'Please enter axis name',
    '测点照片': 'Point Photo',
    '请输入备注': 'Please enter a note',
    '水平径向': 'Horizontal Radial',
    '竖直径向': 'Vertical Radial',
    '传感器编号': 'Sensor Number',
    '轴名称': 'Axis Name',
    '编号': 'Number',
    '测点ID': 'Measurement Point ID',
    '是否试验台': 'Whether it is a test bench',
    '总貌图': 'Overview Image',
    '左键按住可拖动，右键添加标记': 'Left-click to drag, right-click to add a mark',
    '关闭原因': 'Reason for Closure',
    '旋转': 'Rotate',
    '更换3D图': 'Change 3D Image',

    //报告

    '选择设备区域': 'Select Equipment Area',
    '选择严重程度': 'Select Severity Level',
    '请输入关键词搜索': 'Enter Keyword for Search',
    '检修详情': 'Maintenance Details',
    '开始时间': 'Start Time',
    '结束时间': 'End Time',
    '检修人员': 'Maintenance Personnel',
    '现场设备状态': 'On-site Equipment Status',
    '故障描述': 'Fault Description',
    '健康设备': 'Healthy Equipment',
    '可用设备': 'Alert Equipment',
    '警戒设备': 'Alarm Equipment',
    '故障设备': 'Danger Equipment',
    '分析周期': 'Analysis Period',
    '故障模式统计(本年)': 'Failure Mode Statistics (This Year)',

    '本周检修/检查': 'Maintenance/Inspection this Week',
    '本月检修/检查': 'Maintenance/Inspection this Month',
    '周报转发：复制链接转发，用户可在电脑端或手机端查看': 'Weekly Report Forwarding: Copy the link to forward, users can view it on a computer or mobile device',
    '手机端链接': 'Mobile Device Link',
    //角色
    '参数配置': 'Parameter Configuration',
    '操作日志': 'Operation Log',
    '登录日志': 'Login Log',
    '数据变更日志': 'Data Change Log',
    '数采器列表': 'Data Acquisition Unit List',
    '传感器监测': 'Sensor Monitoring',
    '服务监测': 'Service Monitoring',
    '系统监测报警': 'System Monitoring Alarm',
    '工况对接配置': 'Operating Condition Interface Configuration',
    '周报配置': 'Weekly Report Configuration',

    '智能诊断报警配置': 'Smart Diagnostic Alarm Configuration',
    '标准监测报警配置': 'Standard Monitoring Alarm Configuration',
    '非标设备管理': 'Non-Standard Equipment Management',
    '设备首页': 'Device Homepage',

    '三级菜单测试第二级': 'Test Third-Level Menu 2',
    '三级菜单测试第三级': 'Test Third-Level Menu 3',
    '设备事件报警': 'Device Event Alarm',

    '测试三级2': 'Test Third-Level 2',
    '故障标准库': 'Fault Standard Library',
    '3D模型库': '3D Model Library',
    '开关机配置': 'Power On/Off Configuration',
    '采集器列表': 'Data Collector List',

    '昵称/用户名/手机号': 'Nickname/Username/Phone Number',
    '请选择所属工厂': 'Please Select the Affiliated Factory',
    '配置': 'Configuration',
    '配置权限': 'Configure permissions',
    '输入用户名': 'Enter Username',
    '输入昵称': 'Enter Nickname',
    '不修改密码无需输入': 'No need to enter if not changing password',
    '请输入角色名称': 'Please Enter the Role Name',
    '请选择菜单': 'Please Select Menu',
    '请选择工厂权限': 'Please Select Factory Permissions',
    '工厂权限': 'Factory Permissions',
    '确定删除此条数据么': 'Are you sure you want to delete this data',

    '菜单名称': 'Menu Name',
    '菜单路由': 'Menu Route',
    '菜单Code': 'Menu Code',
    '上级菜单': 'Parent Menu',
    '菜单类型': 'Menu Type',
    '按钮': 'Button',
    '链接': 'Link',
    '路由地址': 'Route Address',
    '请输入路由地址': 'Please Enter Route Address',
    '菜单排序': 'Menu Order',
    '菜单状态': 'Menu Status',
    '一级菜单': 'One-level menu',

    '定位': 'Positioning',
    '集团': 'Group',
    '请选择集团': 'Please Select Group',

    '故障释义': 'Fault Explanation',
    '一级分类': 'Primary Classification',
    '请输入一级分类': 'Please Enter Primary Classification',
    '二级分类': 'Secondary Classification',
    '请输入二级分类': 'Please Enter Secondary Classification',
    '三级分类': 'Tertiary Classification',
    '请输入三级分类': 'Please Enter Tertiary Classification',
    '缩写': 'Abbreviation',
    '请输入缩写': 'Please Enter Abbreviation',
    '模型图': 'Model Diagram',
    '输入区域或设备名称筛选': 'Enter Area or Device Name for Filtering',
    '未选择设备或没有配置测点': 'No Device Selected or No Measurement Points Configured',
    '粘贴': 'Paste',
    '确定删除所选数据么': 'Do you want to delete the selected data',
    '请选择复制的内容': 'Please select the content to copy',
    '不允许粘贴': 'Paste not allowed',
    '没有复制的内容': 'No content copied',
    '请选择内容': 'Please select content',
    '振动参数': 'Vibration parameters',
    '请选择项目': 'Please select an item',
    '诊断工单(待完成)': 'Diagnostic work order (pending)',
    '诊断工单(已完成)': 'Diagnostic work order (completed)',
    '确认删除': 'Confirm deletion',
    '请填写诊断判断备注': 'Please fill in diagnostic judgment remarks',
    '保存任务处理': 'Save task processing',
    '请输入切片数': 'Enter the number of slices',
    '请选择工厂后提交': 'Please select the factory before submitting',
    '周报月报配置': 'Weekly and monthly report configuration',
    '报告生成策略': 'Report generation strategy',
    '是否生成报告': 'Generate report',
    '每月第': 'Every month on the',
    '每周第': 'Every week on the',
    '天生成月报': 'day generate monthly report',
    '天生成周报': 'day generate weekly report',
    '星期一': 'Monday',
    '星期二': 'Tuesday',
    '星期三': 'Wednesday',
    '星期四': 'Thursday',
    '星期五': 'Friday',
    '星期六': 'Saturday',
    '星期日': 'Sunday',

    '第': 'On the',
    '小时生成报告': 'hour generate report',

    '故障信息(请勾选此次维修的故障项)': 'Fault information (please select the faults for this repair)',
    '维修详情': 'Repair details',
    '故障信息(请勾选此次关闭的故障项)': 'Fault information (please select the faults to be closed this time)',
    '检修图片': 'Maintenance pictures',
    '请选择开始时间': 'Please select start time',
    '请选择结束时间': 'Please select end time',
    '请填写检修人员': 'Please fill in maintenance personnel',
    '请选择现场设备状态': 'Please select on-site equipment status',
    '请填写故障原因': 'Please fill in the cause of the fault',
    '请填写检修内容': 'Please fill in maintenance content',
    '新增成功': 'Added successfully',
    '请选择关闭的故障项': 'Please select the faults to be closed',
    '低频加速度X(m/s2)': 'Low frequency acceleration X (m/s2)',
    '低频加速度Y(m/s2)': 'Low frequency acceleration Y (m/s2)',
    '低频加速度Z(m/s2)': 'Low frequency acceleration Z (m/s2)',
    '温度(­°C)': 'Temperature (°C)',
    '温度(°C)': 'Temperature (°C)',
    '速度X(mm/s)': 'Velocity X (mm/s)',
    '速度Z(mm/s)': 'Velocity Z (mm/s)',
    '速度Y(mm/s)': 'Velocity Y (mm/s)',
    '高频加速度(m/s2)': 'High frequency acceleration (m/s2)',

    '低频加速度X': 'Low frequency acceleration X',
    '低频加速度Y': 'Low frequency acceleration Y',
    '低频加速度Z': 'Low frequency acceleration Z',
    '速度X': 'Velocity X',
    '速度Z': 'Velocity Z',


    '健康设备数': 'Healthy devices \n count',
    '可用设备数': 'Alert devices \n count',
    '警戒设备数': 'Alarm devices \n count',
    '故障设备数': 'Danger devices \n count',
    '停机设备数': 'Stopped devices \n count',
    '待机设备数': 'Stopped devices \n count',
    '运行设备数': 'Running devices \n count',

    '高频加速度(长)': 'High frequency acceleration(long)',
    '速度Y': 'Velocity Y',
    '趋势图': 'Trend chart',
    '时域图': 'Time domain chart',
    '有效值': 'Effective value',
    '倍频': 'Harmonic',
    '边频': 'Sideband',
    '清空标注': 'Clear annotations',
    '值': 'Value',
    '暂无下载文件': 'No files available for download',

    '频谱': 'Spectrum',
    '选择范围': 'Select range',
    '包络解调': 'Enveloping demodulation',
    '采样时间': 'Sampling time',
    '振动指标-均方根': 'Vibration indicator - RMS',
    '振动指标-00.3KHz均方根': 'Vibration indicator - 00.3KHz RMS',
    '振动指标-01KHz均方根': 'Vibration indicator - 01KHz RMS',
    '振动指标-1K10KHz均方根': 'Vibration indicator - 1K10KHz RMS',
    '振动指标-10K25.6KHz均方根': 'Vibration indicator - 10K25.6KHz RMS',
    '振动指标-1K25.6KHz均方根': 'Vibration indicator - 1K25.6KHz RMS',
    '振动指标-025.6KHz均方根': 'Vibration indicator - 025.6KHz RMS',
    '振动指标-优化峭度01': 'Vibration indicator - Optimized kurtosis 01',
    '振动指标-平稳冲击烈度': 'Vibration indicator - Smoothed impulsive intensity',
    '振动指标-线性峭度02': 'Vibration indicator - Linear kurtosis 02',
    '振动指标-峭度因子': 'Vibration indicator - Kurtosis factor',
    '振动指标-峰峰值': 'Vibration indicator - Peak-to-peak value',
    '振动指标-优化峰值': 'Vibration indicator - Optimized peak value',
    '振动指标-优化偏度': 'Vibration indicator - Optimized skewness',
    '振动指标-速度均方根': 'Vibration indicator - Velocity RMS',
    '振动指标-频率集中度': 'Vibration indicator - Frequency concentration',
    '振动指标-0.31KHz均方根': 'Vibration indicator - 0.31KHz RMS',
    '振动指标-1K3KHz均方根': 'Vibration indicator - 1K3KHz RMS',
    '振动指标-3K6KHz均方根': 'Vibration indicator - 3K6KHz RMS',
    '振动指标-6K12KHz均方根': 'Vibration indicator - 6K12KHz RMS',
    '振动指标-1200025600Hz均方根': 'Vibration indicator - 1200025600Hz RMS',
    '振动指标-6K10KHz均方根': 'Vibration indicator - 6K10KHz RMS',
    '振动指标-优化均方根': 'Vibration indicator - Optimized RMS',
    '振动指标-偏度因子': 'Vibration indicator - Skewness factor',
    '振动指标-裕度因子': 'Vibration indicator - Kurtosis factor',
    '振动指标-峰值指标': 'Vibration indicator - Peak value index',
    '振动指标-包络信息熵': 'Vibration indicator - Envelope entropy',
    '振动指标-包络幅值峭度': 'Vibration indicator - Envelope amplitude kurtosis',
    '振动指标-脉冲因子': 'Vibration indicator - Impulse factor',
    '振动指标-波形因子': 'Vibration indicator - Waveform factor',
    '振动指标-均值频率': 'Vibration indicator - Mean frequency',
    '振动指标-主频带位置': 'Vibration indicator - Main frequency band position',
    '振动指标-时域信息熵': 'Vibration indicator - Time domain entropy',
    '振动指标-频域信息熵': 'Vibration indicator - Frequency domain entropy',
    '振动指标-电流指标': 'Vibration indicator - Current index',
    '趋势波形图': 'Trend waveform chart',
    '多时域频谱': 'Multi-time domain spectrum',
    '诊断分数趋势': 'Diagnostic score trend',
    '倒频谱': 'Inverse spectrum',


    '幅值-m/s²': 'Amplitude - m/s²',
    '频率-Hz': 'Frequency - Hz',
    '振动指标-Trms': 'Vibration indicator - Trms',
    '振动指标-Trms_300': 'Vibration indicator - Trms_300',
    '振动指标-Trms_1000': 'Vibration indicator - Trms_1000',
    '振动指标-Trms_1000_10000': 'Vibration indicator - Trms_1000_10000',
    '振动指标-Trms_10000_25600': 'Vibration indicator - Trms_10000_25600',
    '振动指标-Trms_1000_25600': 'Vibration indicator - Trms_1000_25600',
    '振动指标-TLinKurt': 'Vibration indicator - TLinKurt',
    '振动指标-TImpactIntensity': 'Vibration indicator - TImpactIntensity',
    '振动指标-Tlin_kurt': 'Vibration indicator - Tlin_kurt',
    '振动指标-Tkurt': 'Vibration indicator - Tkurt',
    '振动指标-Tff': 'Vibration indicator - Tff',
    '振动指标-TAccelerationPeak': 'Vibration indicator - TAccelerationPeak',
    '振动指标-Tlin_bias': 'Vibration indicator - Tlin_bias',
    '振动指标-TVelocityRMS': 'Vibration indicator - TVelocityRMS',
    '振动指标-Tcon': 'Vibration indicator - Tcon',
    '振动指标-Trms_300_1000': 'Vibration indicator - Trms_300_1000',
    '振动指标-Trms_1000_3000': 'Vibration indicator - Trms_1000_3000',
    '振动指标-Trms_3000_6000': 'Vibration indicator - Trms_3000_6000',
    '振动指标-Trms_6000_12000': 'Vibration indicator - Trms_6000_12000',
    '振动指标-Trms_12000_25600': 'Vibration indicator - Trms_12000_25600',
    '振动指标-Trms_6000_10000': 'Vibration indicator - Trms_6000_10000',
    '振动指标-TAccelerationRMS': 'Vibration indicator - TAccelerationRMS',
    '振动指标-Tbias': 'Vibration indicator - Tbias',
    '振动指标-Tmargin': 'Vibration indicator - Tmargin',
    '振动指标-Tpp': 'Vibration indicator - Tpp',
    '振动指标-Tent_e': 'Vibration indicator - Tent_e',
    '振动指标-Tenv_kurt': 'Vibration indicator - Tenv_kurt',
    '振动指标-Tpulse': 'Vibration indicator - Tpulse',
    '振动指标-Twave': 'Vibration indicator - Twave',
    '振动指标-TF9': 'Vibration indicator - TF9',
    '振动指标-Tmainf': 'Vibration indicator - Tmainf',
    '振动指标-Tent_t': 'Vibration indicator - Tent_t',
    '振动指标-Tent_f': 'Vibration indicator - Tent_f',
    '振动指标-Tcurrent': 'Vibration indicator - Tcurrent',



    'Low frequency acceleration X (m/s2)': '低频加速度X(m/s2)',
    'Low frequency acceleration Y (m/s2)': '低频加速度Y(m/s2)',
    'Low frequency acceleration Z (m/s2)': '低频加速度Z(m/s2)',
    'Temperature (°C)': '温度(°C)',
    'Velocity X (mm/s)': '速度X(mm/s)',
    'Velocity Z (mm/s)': '速度Z(mm/s)',
    'Velocity Y (mm/s)': '速度Y(mm/s)',
    'High frequency acceleration (m/s2)': '高频加速度(m/s2)',
    'Low frequency acceleration X': '低频加速度X',
    'Low frequency acceleration Y': '低频加速度Y',
    'Low frequency acceleration Z': '低频加速度Z',
    'Temperature': '温度',
    'Velocity X': '速度X',
    'Velocity Z': '速度Z',
    'Velocity Y': '速度Y',
    'High frequency acceleration': '高频加速度',

    '设备生命周期管理': 'Device Lifecycle Management',










    status: ['Equipment stops', 'Running', 'Healthy', 'Acceptable', 'Alarm', 'Danger'],
    button: [
      'Nearly a week',
      'Nearly a month',
      'Nearly two months',
      'Customize',
      'Filter',
      'Turn on',
      'Close',
      'Auto refresh',
      'Nearly a day',
      'View all',
      'Add',
      'Confirm',
      'Cancel',
      'Edit',
      'Search',
      'Download',

    ],
    tip: ['Loading...', 'Check', 'Operate',],
    header: [
      'Tip: The strength of the original password is too low, please change the password!',
      'Original password：',
      'New password:',
      '*Password cannot be less than 8 characters',
      'Confirm password:',
      'Change password',
    ],
    primitive: [
      'Continuous service operation of the system',
      'Hour',
      'Factory health score',
      'Score',
      'For the equipment in operation, the weighted average of status points is calculated as the factory health score , with 100 points for healthy equipment, 80 points for usable equipment, 60 points for warning equipment, and 30 points for faulty equipment',
      'Last updated time:',
      'More device information',
      'Device report',
      'Personal report',
      'Diagnosis',
      'Overhaul',
      'Weekly report',
      'Monthly report',
      'More reports'
    ],
    statusRander: ['Total number of devices:', 'Device diagnosis radar chart', 'Health indicators', 'Vibration indicators', 'Some measuring point data has not been returned, so the score is for reference only'],
    diagnosis: [
      'Equipment list',
      'Cumulative boot time:',
      'Hour',
      'Overall health status:',
      'Current device status:',
      'Show problem details only',
      'Device health event timeline',
      'Device status changes',
      'On-site inspection',
      'Field maintenance',
      'Shut down the machine for maintenance',
      'Find obvious abnormal features, check the status of equipment on site, formulate maintenance plans, and maintain equipment in a timely manner',
      'Abnormal features are found, and the equipment status is checked on site according to the inspection plan',
      'No action required',
      'Health indicators',
      'Vibration indicators',
      'Some measuring point data has not been returned, so the score is for reference only',
      'Abnormal features are found, and the equipment status is checked on site according to the inspection plan'
    ],
    histrend: [
      'Health diagnosis score trend chart',
      'Switch line',
      'Show',
      'Hide',
      'Vibration indicator diagnostic health score',
    ],
    sensorTrend: [
      'Sensor index value',
      'Modify threshold',
      'Indicator name',
      'Indicator value',
      'Threshold',
      'Sensor infographic',
      'Device event timeline',
      'Add on-site inspection or repair records',
      'Diagnostic reminder',
      'Field maintenance',
      'Diagnostic details',
      'Inspection report details',
      'Inspection result',
      'Maintenance date',
      'On-site device status judgment',
      'On-site feedback details',
      'Time',
      'Health status',
      'Failure mode',
      'Consistency',
      'Remark',
      'Operation record',
      'Site photos',
      'Have not uploaded',
      'Maintenance information',
      'Maintenance person',
      'Maintenance records',
      'Start time',
      'End time',
      'Please select an inspection start time!',
      'Please select an inspection end time!',
      'Failure mode description:',
      'User addition',
      'Maintenance personnel',
    ],
    lowwave: [
      'High frequency acceleration',
      'Low frequency speed',
      'Time domain diagram',
      'Amplitude spectrum',
      'Envelope demodulation',
    ],
    tools: [
      'Envelope demodulation',
      'Tool usage:',
      'Applicable/not applicable scenarios:',
      'Page layout',
      'Refresh data',
      'Indicator trend analysis',
      'Indicator monitoring',
      'Statistical parameters based on the high-frequency acceleration root mean square, temperature, and three-way velocity root mean square collected by the high-frequency temperature vibration sensor every five minutes',
      'It is relatively insensitive to small-scale peeling or scars on the surface, and faults in the form of shock vibration; the fault mode location is not clear and intuitive',
      'Comparative Analysis of Index Values',
      'Different measuring points, directions, and indicators of the equipment can be freely selected, and comparative analysis can be carried out within the selected time period',
      'Through comparison, the fault location can be easily and quickly located, and the fault mode can be clarified; however, a complete equipment diagnosis model must be established in advance, and it is difficult to apply when there are few measurement points and no indicators',
      'Smart Diagnostic Score Trends',
      'Reflect the trend of professional and general index scores of the same measurement point over time',
      'To establish a complete equipment diagnosis model in advance',
      'Spectrum Analysis Tool',
      'Time domain diagram',
      'Display the time-domain waveform of the vibration signal, the amplitude changes with time, and make a preliminary judgment on the equipment failure',
      'Time domain diagram is the most basic and commonly used analysis method, and it is also the basis of other analysis methods; however, it is not easy to judge the information and failure mode in the vibration signal',
      'Amplitude Spectrum',
      'Displays the amplitude of the vibration components at each frequency',
      'Obvious fault characteristics can be found in the amplitude spectrogram, and the specific fault mode of the equipment can be determined; but only the distribution of frequency band energy can be viewed, and the change of specific frequency band energy over time cannot be reflected',
      'Envelope spectrum',
      'Demodulation and extraction of low-frequency impact signals',
      'Eigenfrequency extraction for submerged in high-frequency vibrations, such as bearing faults',
      'Cepstrum diagram',
      'Highlight main frequency components',
      'Suitable for signal analysis of bearings, gears, etc. mixed with various harmonics and multi-component frequency conversion',
      'Axis locus diagram',
      'Use the horizontal radial and vertical radial components of the rotor to plot the motion trajectory of the shaft center',
      'It is suitable for situations where it is necessary to intuitively reflect the rotation and vibration of the rotor in the bearing; however, it relies heavily on the diagnostic experience of the map reader, and it is difficult to identify the complex axis trajectory caused by coupling faults',
      'Spectrum Comparison Tool',
      'Waterfall chart',
      'Reflect the amplitude and speed changes of all frequency components',
      'Applicable to the vibration signal of the start-stop process. The image is intuitive but loses the phase information of vibration, which is not suitable for vibration phase analysis',
      'Trend comparative analysis',
      'Type of data:',
      'Indicators:',
      'time:',
      'Selection period:',
      'Select measuring point:',
      'Choose a type',
      'Eigenfrequency:',
      'Turn on',
      'Turn off',
      'Measuring point:',
      'Type:',
      'Frequency range:',
      'Recommend Rotation frequency:',
      'Rotation frequency:',
      '（*Please click the upper right icon to view larger images）',

    ],
    machinemsg: [
      'Select event type',
      'Intelligent diagnosis',
      'Equipment inspection',
      'Threshold alarm',
      'Select event status',
      'Processed',
      'Unprocessed',
      'Choose to process or not',
      'Event time',
      'Event type',
      'Event status',
      'Measuring point',
      'Event content',
      'Contact person',
      'Event report',
      'Diagnose report',
      'Maintenance report',

    ],
    download: [
      'Equipment:',
      'Time frame:',
      'Type of data:',
      'Steady state',
      'Running state',
      'Sensor measuring point',
      'Health degree',
      'Acquisition user',
      'Asset coding',
      'Asset type',
      'Asset position number',
      'Length',
      'Sensor mounting position',
      'Sensor mounting direction',
      'Acquisition frequency',
      'Acquisition direction',

    ],
    machine: [
      'Device name',
      'Device model',
      'Equipment brand',
      'Installation location',
      'Device area',
      'Device location',
      'Add date',
      'Device description',
    ],
    电机驱动端: 'Motor drive end',
    一轴输入端: 'One shaft input',
    一轴输出端: 'One shaft output',
    二轴输入端: 'Two-shaft input',
    二轴输出端: 'Two-shaft output',
    三轴输出端: 'Three-shaft output',
    滚筒轴承: 'Roller bearings',

    左侧滚筒轴承: 'Left roller bearing',
    左侧电机驱动端: 'Left motor drive end',
    三轴输入端: 'Three-shaft input',
    四轴左侧输出端: 'Four-shaft left output',
    四轴右侧输出端: 'Four-shaft right output',
    右侧滚筒轴承: 'Right roller bearing',
    一轴左侧输入端: 'One shaft left input',
    一轴右侧输入端: 'One shaft right input',
    右侧电机驱动端: 'Right motor drive end',

    四轴输出端: 'Four-shaft input',
    五轴输出端: 'Five-shaft input',


    查看详情: 'View details',
    返回机房: 'Return to the machine room',
    暂无事件: 'No event',
    条: 'Pieces',
    查看更多: 'View More',
    点击查看详情: 'Click for details',
    机房: 'The machine room',
    健康指标: 'Healthy indicators',
    振动指标: 'Vibration indicators',
    分: 'Score',
    系统持续服务运行: "Continuous service operation of the system",
    区域健康评分: 'Regional Healthy score',
    '针对运行中的设备, 状态得分的加权平均数计算为全厂健康分数, 健康设备记100分, 可用设备记80分, 警戒设备记60分, 故障设备记30分': 'For the equipment in operation, the weighted average of status points is calculated as the factory Healthy score , with 100 points for healthy equipment, 80 points for Alert equipment, 60 points for Alarm equipment, and 30 points for Danger equipment',
    传感器安装位置: 'Sensor mounting position',
    转速: 'Rotational speed',
    时间: 'Time',
    操作: 'Operate',
    下载: 'Download',
    条数据: 'Pieces of data',
    请耐心等待完成加载后点击确认即可下载文件请勿反复点击下载或关闭本网站: 'Please wait patiently. After the loading is complete, click OK to download the file. Please do not click download repeatedly or close this website',
    共: 'Totally',
    近一天: 'Nearly a day',
    近一周: 'Nearly a week',
    近一月: 'Nearly a month',
    自定义: 'Customize',
    筛选: 'Filter',
    自动刷新: 'Auto refresh',
    关闭: 'Turn off',
    开启: 'Turn on',
    工况数据: 'Operating data',
    工况名称: 'Operating condition name',
    工况值: 'Operating value',
    工况信息图表: 'Operating condition infographic',
    全部设备: 'All equipment',
    搜索: 'Search',
    搜索设备信息: 'Search device information',
    设备名称: 'Device name',
    设备区域: 'Device area',
    安装位置: 'Installation location',
    当前状态: 'Current status',
    健康状态描述: 'Description of Healthy status',
    状态: 'Status',
    分数等级: 'Score grade',
    建议客户的行为: 'Suggested customer behavior',
    建议使用时间: 'Recommended using time',

    可用上: 'Upper Alert',
    可用下: 'Lower Alert',
    警戒上: 'Slight Alarm',
    警戒中: 'Medium Alarm',
    警戒下: 'Severe Alarm',
    故障上: 'Slight Danger',
    故障下: 'Severe Danger',
    无: 'None',
    无需操作: 'No action required',
    可持续使用: 'Sustainable use',
    一级可用: 'Level 1 Alert',
    二级可用: 'Level 2 Alert',
    一级警戒: 'Alarm level 1',
    二级警戒: 'Alarm level 2',
    发现异常特征关注AI设备卫士中设备分数变化趋势: 'Discover abnormal features and pay attention to the trend of equipment scores in AI equipment guards',
    发现异常特征按照巡查计划现场查看设备状态: 'Abnormal features are found, and the equipment status is checked on site according to the inspection plan',
    天07内查看: 'View within 0-7 days',
    具体时间因设备和故障类型而异: 'Exact times vary by devices and types of fault',
    发现明显异常特征现场检查设备状态根据现场检查结果更新维护计划: 'Find obvious abnormal features, check the status of the equipment on site, and update the maintenance plan according to the results of the on-site inspection',
    天03内检查天170内维护: 'Inspection within 0-3 days, maintenance within 1-70 days',
    发现明显异常特征现场检查设备状态制定维护计划适时维护设备: 'Find obvious abnormal features, check the status of equipment on site, formulate maintenance plans, and maintain equipment in a timely manner',
    天03内检查天130内维护: 'Inspection within 0-3 days, maintenance within 1-30 days',
    停止运行机器进行维护: 'Shut down the machine for maintenance',
    尽快停机: 'Shut down as soon as possible',
    健康状态: 'Healthy status',
    运行中: 'Running',
    已停止: 'Stopped',
    查看: 'Check',
    诊断报告: 'Diagnose report',
    检修报告: 'Maintenance Report',
    设备详情: 'Device details',
    设备型号: 'Device model',
    设备品牌: 'Equipment brand',
    添加日期: 'Add date',
    设备描述: 'Device description',
    个人报告: 'Personal report',
    周报月报: 'Weekly/Monthly report',
    账户管理: 'Account management',

    报警管理: 'Alarm Management',
    状态监测报警: 'Status monitoring alarm',
    智能诊断报警: 'Intelligent diagnosis alarm',
    智能诊断模型配置: 'Smart diagnosis dodel configuration',
    工厂设备管理: 'Factory equipment management',
    设备管理: 'Device management',
    测点管理: 'Measuring point management',

    系统日志: 'System log',
    日志管理: 'Log management',
    访问日志: 'Access log',
    工况触发采集配置: 'Condition trigger collection configuration',
    工况数据管理: 'Operating condition data management',
    工况采集卡管理: 'Operating condition capture card management',
    有线采集卡管理: 'Wired capture card management',
    诊断管理: 'Diagnostic management',
    模型配置: 'Model configuration',
    诊断工单管理: 'Diagnostic worksheet management',
    稳定状态清空: 'Steady-state clearing',
    数据清洗配置: 'Data cleaning configuration',
    搜索设备信息报告内容等: 'Search for device information, report content, and more',
    选择日期: 'Select date',
    设备: 'Equipment',
    诊断描述: 'Diagnostic description',
    严重程度: 'Severity degree',
    最近更新: 'Recently updated',
    轻微: 'Slightly',
    中度: 'Moderate',
    严重: 'Severity',
    诊断详情: 'Diagnostic details',
    健康事件: 'Healthy event',
    可用事件: 'Alert events',
    警戒事件: 'Alarm event',
    故障事件: 'Danger event',
    幅值谱图: 'Amplitude spectrum',
    包络谱图: 'Envelope demodulation',
    故障原因: 'Cause of failure',
    备注: 'Remark',
    报告状态: 'Report status',
    数据源: 'Data source',
    提交日期: 'Submission date',
    已诊断: 'Diagnosed',
    诊断中: 'Diagnosing',
    个人申请: 'Individual application',
    巡检记录: 'Inspection record',
    异常数据报警: 'Abnormal data alarm',
    个人报告信息: 'Personal report information',
    诊断中请耐心等待: 'Diagnosing, please wait patiently',
    当前设备问题严重程度为重度请及时进行检查与维修: 'The severity of the problem is serious. Please check and maintain the device in time',
    当前设备问题严重程度为中度请密切关注请结合现场情况适时检查: 'The severity of the current device fault is moderate. Pay close attention to the problem and check it according to the onsite situation.',
    当前设备问题严重程度为轻度请关注: 'The severity of the current device problem is mild. Please pay attention.',
    诊断结论: 'Diagnostic conclusion',
    报告内容: 'Report content',
    现场照片: 'Site photos',
    未上传: 'Have not uploaded',
    设备信息: 'Device information',
    检修日期: 'Maintenance date',
    检修内容: 'Maintenance content',
    故障类型: 'Fault type',
    检修人: 'Maintenance person',
    提交时间: 'Submission time',
    检修报告详情: 'Inspection report details',
    检修结果: 'Inspection result',
    综合健康状态: 'Overall Healthy status',
    当前设备分数: 'Current device status',
    现场设备状态判断: 'On-site device status judgment',
    现场反馈详情: 'On-site feedback details',
    故障模式: 'Fault mode',
    一致性: 'Consistency',
    一致: 'Unanimous',
    不确定: 'Uncertain',
    不一致: 'Inconformity',
    操作记录: 'Operation record',
    检修信息: 'Maintenance information',
    报告标题: 'Report title',
    报告周期: 'Reporting cycle',
    报告类型: 'Report type',
    周: 'Week',
    月报: 'Monthly report',
    周报: 'Weekly report',
    报告统计时间: 'Report statistics time',
    报告日期: 'Date of report',
    设备详情清单: 'Device details list',
    取消: 'Cancel',
    保存: 'Save',
    设备状态: 'Device status',
    状态变化: 'State change',
    诊断结果: 'Diagnostic result',
    未知因素: 'Unknown factor',
    本周检修检查: "This week's overhaul/inspection",
    pc端链接: 'Computer web link',
    移动端链接: 'Mobile web link',
    周报转发分别复制链接转发用户可在电脑端或手机端查看: 'Weekly report forwarding: copy link forwarding respectively, users can view on the computer or mobile phone',
    本周设备健康总览: 'Overview of device Healthy this week',
    本周设备事件提醒总览: "Overview of this week's device event alerts",
    故障模式统计本年: 'Fault mode statistics (current year)',
    有线采集管理: 'Wired acquisition management',
    成功: 'Succeed',
    失败: 'Failure',
    添加账户: 'Add account',
    昵称: 'Nickname',
    用户名: 'Username',
    角色: 'Role',
    手机号: 'Mobile Number',
    是否启用: 'Enable or not',
    权限标签: 'Permission label',
    关键词: 'Key words',
    昵称用户名手机号: 'Nickname/Username/Mobile Number',
    全部: 'All',
    是: 'Yes',
    否: 'No',
    编辑: 'Edit',
    停用: 'Disabled',
    启用: 'Enable',
    重置密码: 'Reset password',
    修改密码: 'Change password',
    修改账户: 'Modify account',
    请输入用户名: 'Please enter username',
    请输入昵称: 'Please enter nickname',
    密码: 'Password',
    密码至少8位: 'Password cannot be less than 8 characters',
    随机生成: 'Randomly generated',
    请选择角色: 'Please select role',
    工厂: 'Factory',
    请输入手机号: 'Please enter mobile Number',
    请选择工厂: 'Please select factory',
    请选择: 'Please select !',
    确定: 'Confirm',
    重置该用户密码: 'Reset the user password',
    新密码: 'New password',
    旧密码: 'Old password',
    角色名: 'Character name',
    查询: 'Inquire',
    菜单: 'Menu',
    删除: 'Delete',
    新增: 'Add',
    确认删除该角色: 'Confirm to delete this role!',
    保存成功: 'Saved successfully !',
    保存失败: 'Fail to save !',
    编辑成功: 'Edit successfully !',
    操作成功: 'Successful operation !',
    区域: 'Area',
    设备id: 'Device ID',
    设备类型: 'Equipment type',
    厂家: 'Factory',
    型号: 'Model',
    机器SN号: 'Machine SN',
    序号: 'Serial number',
    是否是试验台: 'Is it a test-bed',
    传感器位置: 'Sensor location',
    设备类型缩写: 'Device type abbreviation',
    生产厂家: 'Manufacturer',
    设备SN号: 'Device SN',
    试验台: 'Test-bed',
    版本: 'Version',
    旗舰版: 'Ultimate edition',
    商业版: 'Business edition',
    设备图片: 'Equipment pictures',
    上传: 'Upload',
    提交: 'Submit',
    请输入: 'Please enter',
    操作失败: 'Operation failure',
    文件上传失败请联系管理员: 'File upload failed. Please contact the administrator',
    传感器SN: 'Sensor SN',
    测点id: 'Measuring point ID',
    当前设备: 'Current device',
    转频提取测点: 'Frequency conversion extraction measurement point',
    轴参数: 'Axis parameters',
    DT编号: 'DT number',
    安装坐标: 'Installation coordinates',
    名称: 'Name',
    报警类型: 'Alarm type',
    高频: 'High frequency',
    低频: 'Low frequency',
    路径: 'Route',
    更新时间: 'Updated time',
    当前值: 'Current value',
    特性: 'Peculiarity',
    所属设备: 'Equipment',
    功率: 'Power',
    三轴参数: 'Triaxial parameters',
    数据类型: 'Type of data',
    保存路径: 'Save route',
    上次更新: 'Last update',
    添加: 'Add',
    选择: 'Select',
    搜索测点信息: 'Search measuring point information',
    是否推送: 'Push or not',
    推送人: 'Pusher',
    清空: 'Empty',
    说明: 'Illustrate',
    测点: 'Measuring point',
    指标名称: 'Indicator name',
    单位与描述: 'Unit and description',
    一级门限可用: 'Level 1 threshold - alert',
    报警间隔天: 'Alarm interval - Days',
    二级门限警戒: 'Level 2 threshold - alarm',
    三级门限故障: 'Level 3 threshold - danger',
    不推送: 'Do not push',
    确认: 'Confirm',
    同步标准: 'Synchronization standard',
    基于国际国家或行业内既定标准实现对设备运行状态进行监测同时可纵向参考设备历史运行数据横向比较同类设备运行数据确定各报警等级的阈值: 'Based on international, national or industry-established standards, the monitoring of equipment operating status is realized. at the same time. It can refer to the historical operation data of equipment vertically, compare the operation data of similar equipment horizontally, and determine the threshold value of each alarm level.',
    但依据标准设定的绝对合理的阈值很难获取设定过高的报警阈值会造成设备故障: 'However, it is difficult to obtain an absolutely reasonable threshold set according to the standard. If the alarm threshold is set too high, it will cause the omission risk of equipment',
    漏报: 'Fault reporting',
    的风险而阈值过低又会来带大量的: ', and if the threshold is too low, it will bring a large number of ',
    误报警: 'False alarms',
    给现场处理造成极大的压力此外仅按照标准规定中的几种指标设定的阈值报警所能发现的故障模式有限设置和修改阈值也面临必需手动调整与工作量大等问题: ', which will cause great damage to the on-site processing. pressure; in addition, the fault modes that can be detected only by the threshold alarms set by several specified in the standard regulations are limited. Setting and modifying the threshold also faces problems such as manual adjustment and heavy workload.',
    根据各类国际国家行业标准根据对目前所有设备类型参照相应标准做如下统计: 'According to various international, national and industry standards, and according to all current equipment types, referring to the corresponding standards, the following statistics are made:',
    采用标准: 'Standard',
    电机类: 'Motor series',
    泵类: 'Pump series',
    往复类: 'Reciprocating series',
    风机类: 'Fan series',
    齿轮类: 'Gears series',
    螺杆式: 'Screw series',
    汽轮机: 'Steam turbine',
    通用设备: 'General equipment',
    报警策略: 'Alarm strategy',
    工况异常处理: 'Working condition exception handling',
    是否专家看护: 'Expert care or not',
    分指标报警: 'Sub-indicator alarm',
    按设备整体状态: 'According to the overall status of the equipment',
    单点即刻报警: 'Single point instant alarm',
    短期重复出现报警: 'Short-term recurring alarms',
    智能诊断报警策略组合: 'Intelligent diagnosis and alarm strategy combination',
    一旦监测到分数异常即刻报警: 'Once abnormal scores are detected, send alarms immediately',
    统计短期内重复出现同类型异常报警去除工况突变数据采集等异常值误报: 'Count the repeated occurrence of the same type of abnormal alarms in a short period of time, and remove abnormal value false alarms such as sudden changes in working conditions and data collection',
    每个指标发生分数恶化都单独发送报警: 'Score deterioration for each index sends separate alarms',
    适合处于非健康状态或指标磨合期的设备需要故障模式报警: 'Suitable for equipment that is in an unhealthy state or in the run-in period of indicators, and requires fault mode alarms',
    适合运行较稳定需要故障模式报警: 'Suitable for relatively stable operation, requiring fault mode alarm',
    仅当设备整体状态恶化时发送报警: 'Send alarms only when the overall state of the device deteriorates',
    用于对整体设备状态监测非健康设备报警时效性要求高: 'Be used to monitor the overall equipment status, and the alarm timeliness of non-healthy equipment is high',
    用于对整体设备状态监测设备运行较稳定趋势性变化突发异常概率低: 'Be used to monitor the overall equipment status, the equipment runs relatively stable, and the probability of sudden abnormalities in trend changes is low',
    笔内出现: 'Inside the pen, appear',
    次: 'times',
    开关机影响时间范围: 'Shutdown affected time range',
    分钟: 'minutes',
    范围: 'scope',
    状态回升灵敏度: 'Status recovery sensitivity',
    距最近开机: 'since last turn on',
    笔数据判断状态向前: 'Pen data judgment status, forward',
    笔数据判断状态: 'Pen data judgmenta factory before submitting!',
    请选择区域后再提交: 'Please select a region before submitting!',
    请选择设备后再提交: 'Please select  status.',
    请选择工厂后再提交: 'Please select a device before submitting!',
    请选择报警策略后再提交: 'Please select an alarm strategy before submitting!',
    请填写工况异常处理后再提交: 'Please fill in abnormal working conditions and then submit!',
    该区域中存在重复配置的设备: 'There are duplicate configured devices in the zone',
    首页大屏布局: 'Home screen layout',
    默认版式: 'Default layout',
    设备列表版式: 'Device list layout',
    实验采集版式: 'Experiment collection format',
    操作用户: 'Operating user',
    操作路由: 'Operation routing',
    操作数据: 'Operating data',
    操作时间: 'Operating time',
    设备编号: 'Device ID',
    采集对象: 'Collection object',
    工况类型或编号: 'Case type or number',
    运算符: 'Operator',
    门限值: 'Threshold',
    保持状态延时: 'Hold state delay',
    误差率: 'Error rate',
    波形采集: 'Waveform acquisition',
    监测指标采集: 'Monitoring indicator collection',
    秒: 'Seconds',
    确定要删除: 'Sure to delete?',
    请选择设备后进行操作: 'Select the device and perform operations',
    标签: 'Label',
    类型: 'Type',
    禁用: 'Disabled',
    振动加速度: 'Vibration acceleration',
    振动速度: 'Vibration velocity',
    温度: 'Temperature',
    出口流量: 'Outlet flow',
    频率: 'Frequency',
    实际压力: 'Actual pressure',
    出口压力: 'Outlet pressure',
    电流: 'Electricity',
    电压: 'Voltage',
    扭矩: 'Torque',
    未绑定: 'Not bound',
    请选择设备: 'Please select a device',
    请选择类型: 'Please select a type',
    采集卡编码: 'Capture card code',
    采集卡ip: 'Capture card IP',
    采集卡类型: 'Capture card type',
    工厂名称: 'Factory name',
    传感器通道: 'Sensor channel',
    通道: 'Channel',
    采集卡配置: 'Capture card configuration',
    基本信息: 'Basic information',
    采集配置: 'Collection configuration',
    采集间隔: 'Collection interval',
    工况通道配置: 'Condition channel configuration',
    工况通道号: 'Condition channel number',
    选择测点: 'Select measuring point',
    请填写采集卡编码: 'Please fill in the capture card code',
    请选择采集卡类型: 'Please select the capture card type',
    请选择区域: 'Please select a region',
    请填写安装位置: 'Please fill in the installation location',
    请填写采集卡ip: 'Please fill in the ip address of the acquisition card',
    请完成工况通道配置的编辑后提交: 'Please finish editing the working channel configuration and submit',
    请选择采集卡类型后操作: 'Select a collection card type and perform operations !',
    采集卡id: 'Capture card id',
    ip地址: 'IP address',
    边缘电脑绑定: 'Edge computer binding',
    波形采集配置: 'Waveform acquisition configuration',
    采集方式: 'Collection method',
    波形间隔: 'Waveform interval',
    采样频率: 'Sampling frequency',
    采样时长: 'Sampling duration',
    监测指标采集配置: 'Monitoring indicator collection configuration',
    均方根间隔: 'RMS interval',
    触发条件需要同时满足不填写触发条件系统即认为触发条件满足等同于定时采集: 'The trigger conditions need to be met at the same time. If the trigger condition is not filled in, the system will consider that the trigger condition is met, which is equivalent to timing acquisition',
    通道配置: 'Channel configuration',
    通道号: 'Channel number',
    关联测点名称: 'Associated measuring point name',
    灵敏度: 'Sensitivity',
    轴向: 'Axial direction',
    轴向名称: 'Axial direction name',
    定时采集: 'Timing acquisition',
    满足触发条件的定时采集: 'Periodic collection that meets trigger conditions',
    所选通道数量小于通道配置数量: 'The number of selected channels is smaller than the configured number of channels',
    请选择采集卡类型后再添加: 'Select the card type and add it again',
    通道数量超过所选的: 'The number of channels exceeds the selected',
    请填写ip地址: 'Please fill in the IP address',
    请选择波形采集配置的波形间隔: 'Please select the waveform interval of the waveform acquisition configuration',
    请选择波形采集配置的采样频率: 'Please select the sampling frequency of the waveform acquisition configuration',
    请选择波形采集配置的采样时长: 'Please select the sampling duration of the waveform acquisition configuration',
    请选择检测指标采集配置的波形间隔: 'Please select the waveform interval of the detection index acquisition configuration',
    请选择检测指标采集配置的采样频率: 'Please select the sampling frequency of the detection index acquisition configuration',
    请完成通道配置的编辑后提交: 'Please submit after editing the channel configuration',
    通道号过大: 'Channel number too large',
    请填写灵敏度后提交: 'Please fill in the sensitivity and submit',
    切片数: 'Number of slices',
    滤波方式: 'Filtering method',
    滤波频段: 'Filter band',
    请选择滤波方式: 'Please select a filtering method',
    新增行: 'Add new line',
    选择设备: 'Select device',
    带通滤波: 'Band-pass filtering',
    带阻滤波: 'Band-stop filtering',
    设备选择: 'Evice selection',
    操作日期范围: 'Operation date range:',
    重置: 'Reset',
    项目: 'Project',
    状态清空时间: 'Status clear time',
    负责人: 'Person in charge',
    上一小时状态: 'Last hour status',
    是否改变状态: 'Whether to change state',
    设备稳定状态清空: 'Clear the device stable state',
    指标: 'Indicators',
    状态清零记录: 'Status clear record',
    状态回升记录: 'Satus recovery record',
    该设备在此时间点为关机状态请重新选择: 'The device is powered off at this point in time. Please select again',
    选择的时间点暂无稳定状态请重新选择: 'The selected time point is in no stable state. Please select another time point',
    请选择测点后再选择指标: 'Please select the measurement point and then select the indicator',
    请选择日期: 'Please select a date',
    日期范围: 'Date range',
    设备参数配置: 'Device parameter configuration',
    专业指标: 'Professional indicators',
    通用指标: 'Common indicators',
    其他: 'Other',
    其它: 'Other',
    复制: 'Copy',
    粘贴覆盖: 'Paste overlay',
    粘贴追加: 'Paste append',
    恢复为该测点对应的阈值标准值: 'Restore to the threshold standard value corresponding to the measuring point',
    未配置标准则保留配置值: 'If no standard is configured, the configured value is retained',
    细分故障: 'Segmentation fault',
    计算方法: 'Calculation method',
    全选: 'Select all',
    复制成功: 'Successful replication!',
    请先选择需要复制的内容: 'Please first select what you want to copy',
    粘贴成功: 'Paste successfully',
    复制内容无法粘贴在该指标下: 'Copy content cannot be pasted under this indicators!',
    请先复制内容: 'Please copy the contents first!',
    删除成功: 'Successfully deleted',
    请先选择需要删除的内容: 'Please select the content you want to delete first',
    请保存后再切换: 'Please save and switch again!',
    确认执行该操作: 'Confirm to perform this operation!',
    返回: 'Return',
    部件参数: 'Component parameters',
    通用参数: 'General parameters',
    部件参数新增: 'Component parameter new',
    部件类型: 'Part type',
    配置参数: 'Configuration parameters',
    数量: 'Amount',
    参数释义: 'Parameter interpretation',
    客户: 'Client',
    诊断经理: 'Diagnostic manager',
    是否采纳: 'Whether to adopt',
    反馈是否有故障: 'Whether there is machine failure',
    是否曾发送诊断事件: 'Whether a diagnostic event was sent',
    是否更改稳定状态: 'Whether to change the stable state',
    诊断处理: 'Diagnostic processor',
    发生时间: 'Time of occurrence',
    客户经理: 'Account manager',
    稳定状态: 'Steady state',
    上一小时稳定状态: 'Steady state for the last hour',
    事件状态: 'Event status',
    是否前台显示: 'Whether to display in the foreground',
    故障模式一致性: 'Fault mode consistency',
    诊断确认: 'Confirmation of diagnosis',
    调整阈值: 'Adjust threshold',
    专家报告: 'Expert report',
    状态清零: 'Status reset',
    诊断事件: 'Diagnostic event',
    待完成: 'To be completed',
    已完成: 'Completed',
    客户反馈: 'Client feedback',
    确认提交: 'Confirm submission',
    导出详情: 'Export details',
    导出: 'Export',
    我的未完成项: 'My unfinished items',
    取消选择: 'Cancel selection',
    内部事件类型: 'Internal event type',
    警戒及以下: 'Alarm and below',
    诊断待处理: 'Diagnosis pending',
    客户支持待处理: 'Customer support pending',
    完成: 'Completed',
    诊断判断内部备注: 'Diagnosis judgment internal remarks',
    诊断判断备注: 'Remarks for diagnosis judgment',
    专家报告id: 'Expert report ID',
    添加专家报告: 'Add expert report',
    请确认是否清空当前设备稳定状态: 'Confirm whether to clear the current device stable state',
    客户支持: 'Customer support',
    事件进展: 'Progress of events',
    反馈一致性: 'Feedback consistency',


    自动诊断: 'Auto diagnosis',
    人工诊断: 'Manual diagnosis',
    客户支持备注: 'Customer support remark',
    附件: 'Attachment',
    否无效反馈: 'No, the feedback is invalid',
    否模糊: 'No, fuzzy',
    状态反馈一致性: 'State feedback consistency',
    认同: 'Identify with',
    不认同: 'Disagree',
    修改后认同: 'Modified approval',
    部分一致: 'Partial agreement',
    去处理: 'To handle',
    有测点数据未传回因此本条分数数据仅供参考: 'Some measuring point data has not been returned, so the score is for reference only',
    传感器测点: 'Sensor measuring point',
    健康度: 'Healthy degree',
    子指标名称: 'Sub-indicator name',
    子指标值: 'Sub-indicator value',
    添加现场检查或维修记录: 'Add on-site inspection or repair records',
    查看全部: 'View all',
    维修记录: 'Maintenance records',
    检查记录: 'Inspection records',
    检修时间: 'Maintenance time',
    诊断提醒: 'Diagnostic reminder',
    现场维修: 'Field maintenance',
    现场检查: 'On-site inspection',
    健康诊断分数趋势图: 'Healthy diagnosis score trend chart',
    振动指标诊断健康分数: 'Vibration indicator diagnostic Healthy score',
    指标趋势图: 'Indicator trend chart',
    未开机: 'Not powered on',
    智能诊断: 'Intelligent diagnosis',
    门限报警: 'Threshold alarm',
    指标监测: 'Index monitoring',
    智能诊断分数趋势: 'Smart diagnostic score trends',
    指标值对比分析: 'Comparative analysis of index values',
    时域波形图: 'Time domain diagram',
    倒频谱图: 'Cepstrum diagram',
    轴心轨迹图: 'Axis locus diagram',
    瀑布图: 'Waterfall chart',
    高频加速度: 'High frequency acceleration',
    幅值: 'Amplitude',
    倒频率: 'Cepst frequency',
    加载中: 'Loading...',
    "智能诊断-指标值及分数导出": 'Smart diagnosis - export of index values and scores',
    "实时监测-指标值导出": 'Real-time monitoring - indicator value export',
    "事件消息-事件清单导出": 'Event message - event list export',
    "app采集数据- 采集记录导出": 'App collection data - collection record export',
    "原始波形文件下载": 'Original wave file download',
    请选择测点: 'Please select a measuring point',
    请选择时间范围: 'Please select a time range',
    请选择数据类型: 'Please select a data type',
    开: 'Turn on',
    关: 'Turn off',
    全设备测点: 'All equipment measuring points',
    设备维修: 'Equipment repair',
    设备检查: 'Equipment inspection',
    更换部件: 'Parts replacement ',
    维护: 'Maintenance',
    返厂: 'Return to factory',
    登录: 'Log in',
    请输入密码: 'Please enter password',
    "[安全退出]": '[Log out safely]',
    智能大屏: 'Smart screen',
    角色名称: 'Role name',
    工单查看: 'Worksheet view',

    小时: 'Hour',
    小时s: 'Hours',
    请输入操作记录: 'Please enter the operation record!',
    用户添加: 'User addition',
    暂无数据: 'No data',

    变频设备常用转频: 'Common frequency conversion of frequency conversion equipment',
    是否需要在该测点数据本身找转频: 'Do you need to find the conversion frequency in the measurement point data itself',
    额定转频: 'Rated frequency conversion',
    是否需要细化频谱: 'Do you need to refine the spectrum',
    额定电源频率: 'Rated power frequency',
    理论转频所在频谱: 'Theoretical frequency spectrum',
    转频换算系数: 'Frequency conversion coefficient',
    转频或部件频率上限: 'Upper limit of frequency conversion or component frequency',
    转频或部件频率下限: 'Lower limit of frequency conversion or component frequency',
    包络谱找转频滤波频段下限: 'Envelope spectrum to find the lower limit of the conversion frequency filter frequency band',
    转频数据类型: 'Frequency conversion data type',
    转频比值: 'Frequency conversion ratio',
    叶轮: 'Impeller',
    叶轮级数: 'Number of impeller stages',
    叶片数目: 'Number of blades',
    轴承: 'Bearing',
    保持架特征系数: 'Cage characteristic coefficient',
    滚动体特征系数: 'Rolling element characteristic coefficient',
    外圈特征系数: 'Outer ring characteristic coefficient',
    内圈特征系数: 'Inner ring characteristic coefficient',
    轴承型号: 'Bearing type',
    齿轮箱: 'Gearbox',
    不超过1个: 'No more than 1',
    齿轮箱种是否含有蜗轮蜗杆: 'Whether the gearbox type contains a worm gear',
    齿数: 'Number of gear teeth',
    蜗杆的头数和蜗轮的齿数: 'Number of the worm heads and number of worm gear teeth',
    柱塞泵: 'Piston pump',
    不与其他共同选择: 'Not co-opted with others',
    柱塞数目: 'Number of piston',
    柱塞泵滤波参数: 'Piston pump filter parameters',
    柱塞泵转频上限: 'Upper limit of piston pump frequency conversion ',
    柱塞泵转频下限: 'Lower limit of piston pump frequency conversion ',
    行星齿轮箱: 'Planetary gearbox',
    太阳轮齿数: 'Number of teeth of sun gear',
    一倍啮合频率: 'Double meshing frequency',
    行星轮个数: 'Number of planetary gear',
    "齿轮箱(新的指标提取方式)": 'Gearbox (new indicator extraction method)',
    齿轮齿数: 'Number of gear teeth',
    螺杆齿数: 'Number of screw teeth',
    刀头磨损: 'Blade wear',
    叶片积灰: 'Blade dust',
    异常冲击: 'Abnormal impact',
    异常特征: 'Abnormal features',
    叶轮异常: 'Vbnormal impeller',
    冲击烈度: 'Impact intensity',
    机械松动: 'Mechanical loosening',
    滑动轴承松动: 'Sliding bearing loosening',
    基础松动: 'Foundation loosening',
    配合松动: 'Fit loosening',
    机械碰摩: 'Mechanical friction',
    缺水干磨: 'Dry grinding',
    配合不良: 'Poor fit',
    零件磨损: 'Parts wear',
    非平稳故障: 'Non-stationary failure',
    气路共振: 'Air path resonance',
    滚动轴承润滑不良: 'Poor lubrication of rolling bearing',
    滑动轴承油膜涡动: 'Sliding bearing oil film whirling',
    滑动轴承油膜振荡: 'Sliding bearing oil film oscillation',
    滑动轴承烧瓦: 'Sliding bearing bush-burning ',
    滑动轴承磨损: 'Sliding bearing wear',
    滚动轴承异常: 'Rolling bearing failure',
    电磁振动: 'Electromagnetic vibration',
    螺杆啮合不良: 'Poor meshing of the screw',
    行星齿轮啮合不良: 'Poor meshing of planetary gear',
    转子不对中: 'Rotor misalignment',
    转子不平衡: 'Rotor unbalance',
    连杆异常: 'Abnormal connecting rod',
    部件碰摩: 'Part rubbing',
    滑阀碰摩: 'Spool valve rubbing',
    滑块碰摩: 'Slider rubbing',
    滑动轴承碰摩: 'Sliding bearing rubbing',
    陶瓷片脱落: 'Ceramic chip detachment',
    齿轮啮合不良: 'Poor gear meshing',

    明显发展: 'Obviously developing',
    发展: 'Developing',
    轻微发展: 'Slightly developing',
    异常特征减弱: 'Anomalous features weakened',
    有变化: 'Have changes',
    无明显变化: 'No significant changes',
    恢复健康: 'Regain health',
    短暂开机: 'Short boot',
    传输异常: 'Transmission exception',


    振动强度S1: 'Vibration intensity S1',
    振动冲击S2: 'Vibration shock S2',
    振动平衡S3: 'Vibration balance S3',
    振动极端S4: 'Vibration extremes S4',
    振动集中S5: 'Vibration concentration S5',
    振动强度S6: 'Vibration intensity S6',
    振动强度S7: 'Vibration intensity S7',
    振动强度S8: 'Vibration intensity S8',
    振动冲击S9: 'Vibration shock S9',
    振动冲击S10: 'Vibration shock S10',
    振动集中S11: 'Vibration concentration S11',
    振动冲击S12: 'Vibration shock S12',
    振动强度S13: 'Vibration intensity S13',
    振动冲击S14: 'Vibration shock S14',
    振动冲击S15: 'Vibration shock S15',
    振动冲击S16: 'Vibration shock S16',
    振动冲击S17: 'Vibration shock S17',
    振动平衡S18: 'Vibration balance S18',
    振动冲击S19: 'Vibration shock S19',
    振动强度S20: 'Vibration intensity S20',
    振动强度S21: 'Vibration intensity S21',
    振动强度S22: 'Vibration intensity S22',
    振动强度S23: 'Vibration intensity S23',
    振动强度S24: 'Vibration intensity S24',
    振动强度S26: 'Vibration intensity S26',
    振动强度S27: 'Vibration intensity S27',
    电流指标S25: 'Electric current indicators S25',
    平稳冲击烈度S28: 'Stable impact intensity S28',
    速度均方根S29: 'Velocity RMS S29',
    优化均方根S30: 'Optimize RMS S30',
    优化峰值S31: 'Optimize peak S31',
    优化峰值: 'Optimize peak',
    平稳冲击烈度S32: 'Stable impact intensity S32',
    优化峭度S33: 'Optimize kurtosis S33',
    振动强度S34: 'Vibration intensity S34',
    振动强度S35: 'Vibration intensity S35',
    振动强度S36: 'Vibration intensity S36',
    总设备数: 'Total number \n of device',
    设备数: 'number of device',
    速度: 'Speed',
    高频均方根: 'High frequency RMS',
    智能设备大屏: 'Smart screen for device',
    设备诊断: 'Device diagnosis',

    系统管理: 'System management',
    诊断趋势: 'Diagnostic trends',
    标准监测: 'Standard monitoring',
    图谱分析: 'Spectrum analysis',
    分析工具: 'Analyzing tool',
    设备事件: 'Device event',
    设备档案: 'Device profile',
    数据下载: 'Data download',


    泵输入端: 'Pump input end',
    滚动轴承润滑特征: 'Lubrication characteristics of rolling bearings',
    信号规律性特征: 'Signal regularity',
    "10～25.6KHz均方根": '10～25.6KHz RMS',
    优化峭度: 'Optimize kurtosis',
    "1～10KHz均方根": '1～10KHz RMS',
    速度均方根: 'Velocity RMS',
    滚动轴承内圈与外圈: 'Inner ring and outer ring of rolling bearing',
    "泵输入端存在异常特征，判定为滚动轴承异常，故障部位为轴承内圈和外圈，与2022/1/12事件相比，程度进一步发展；请密切关注设备状态与分数变化情况，并结合现场情况适时安排检查。": 'There is an abnormal feature in the pump input end, which is judged to be an abnormal rolling bearing. The abnormal part may be the Inner ring and outer ring of the rolling bearing. Please pay attention to the change of equipment status and score.',
    泵端面: 'Pump end face',
    非平稳故障01: 'Non-stationary failure 01',
    非平稳故障02: 'Non-stationary failure 02',
    机械碰摩01: 'Mechanical friction 01',
    机械碰摩02: 'Mechanical friction 02',
    缺水干磨01: 'Dry grinding 01',
    缺水干磨02: 'Dry grinding 02',
    配合不良01: 'Poor fit 01',
    配合不良02: 'Poor fit 02',
    零件磨损01: 'Parts wear 01',
    零件磨损02: 'Parts wear 02',
    峰峰值: 'Peak-to-peak value',
    频谱集中度: 'Spectrum concentration',
    "0～0.3KHZ均方根": "0～0.3KHZ RMS",
    全频段均方根: 'Full band root mean square',
    "0～1KHZ均方根": '0～1KHZ RMS',
    偏度因子: 'Skewness factor',
    缺水干磨主特征: 'Dry grinding features',
    振动冲击指标12: 'Vibration shock S12',
    振动冲击指标19: 'Vibration shock S19',
    振动集中指标11: 'Vibration concentration S11',
    平稳冲击烈度: 'Stable impact intensity',
    碰摩总体特征: 'Collision population characteristics',
    碰摩次阶特征: 'Collision order characteristics',
    碰摩谐波特征: 'Collisional harmonic characteristic',
    碰摩主特征2: 'Collisional friction main characteristics',
    叶片低阶特征: 'Blade lower-order feature',
    叶片中阶特征: 'Blade middle-order feature',
    不对中低阶特征: 'Misalignment lower-order feature',
    不对中中阶特征: 'Misalignment middle-order feature',
    不对中轴向特征: 'Misalignment axial feature',

    '泵输入端：滚动轴承异常；': 'Pump input end ：abnormal rolling bearing；',
    '泵输入端：部件碰摩、转子不对中；': 'Pump input end ：part rubbing、rotor misalignment；',
    '泵输入端：滚动轴承异常、转子不对中与部件碰磨': 'Pump input end ：abnormal rolling bearing、rotor misalignment and part rubbing',
    滚动轴承内圈和外圈: 'Inner ring and outer ring of rolling bearing',
    "泵端面：部件碰摩；": 'Pump end face : part rubbing；',
    "非平稳故障01，非平稳故障02，振动冲击S19。": 'Non-stationary failure 01、non-stationary failure 02、vibration shock S19。',
    "非平稳故障02。": 'Non-stationary failure 02。',


    '2号反渗透1号增压泵': 'NO.2 Reverse osmosis booster pump # 1',
    'A线3号超滤循环泵': 'A line NO.3 Ultrafiltration circulating pump',

    滚动轴承损伤: 'Rolling bearing damage',
    "非平稳故障01。": "Non-stationary failure 01.",
    "机械碰磨02。": "Mechanical friction 02.",
    电源谐波特征: 'The harmonic characteristics of a power supply',
    不平衡主特征: 'The main characteristics of an unbalanced power system',
    优化峭度指标: 'Optimizing the kurtosis index',
    平稳冲击烈度指标: 'Optimizing the stationary shock intensity index',
    振动速度均方根指标: 'Optimizing the root mean square (RMS) vibration velocity index',
    外圈特征: 'Outer ring features" or "external ring characteristics',
    内圈特征: 'Inner ring features" or "internal ring characteristics',
    滚动体特征: 'Rolling element features" or "characteristics of rolling elements',
    保持架特征: 'Cage features" or "characteristics of the cage',
    振动加速度峰值指标: "Vibration acceleration peak index",
    报告总览: 'Report Overview',
    报告时间: 'Report Time',
    事件是否处理: 'Event processing or not',
    变化趋势: 'Variation trend',
    传感器电量: 'Sensor battery level',
    "设备位号": 'Device Tag Number',
    "请输入设备位号": 'Please enter the device tag number',
    "设备功率": 'Device Power',
    "请输入设备功率": 'Please enter the device power',
    "设备ID": 'Device ID',
    "请输入设备ID": 'Please enter the device ID',
    "设备执行人": 'Device Operator',
    "请输入设备执行人": 'Please enter the device operator',
    "检测类别": 'Detection Category',
    "在线监测": 'Online Monitoring',
    "巡检": 'Inspection',
    "报告编号": 'Report ID',
    "查看报告": "View the report",
    "设备绑定": "Device binding",
    "报警内容": 'Alarm message',
    '发现异常特征，按照巡查计划现场查看设备状态': "Identify abnormal features and inspect equipment status on-site according to the inspection schedule",
    '发现明显异常特征，现场检查设备状态，根据现场检查结果更新维护计划，适时维护设备': 'Identify obvious abnormal features, inspect equipment status on-site, update maintenance plan based on on-site inspection results, and maintain equipment in a timely manner',
    '设备故障严重，建议停机维修': 'The equipment failure is serious, it is recommended to shut down for maintenance',
    '1K～10KHz均方根': '1K～10KHz RMS',
    '10K～25.6KHz均方根': '10K～25.6KHz RMS',
    '巡检阈值管理': 'Inspection Threshold Management'
  },
};
